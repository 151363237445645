import React, { useState,useEffect,useContext } from 'react'
import TPHeader from './TPCOMPONENTS/tp-header'
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu'
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { Alert } from '../Components/reusables';
import axios from 'axios';
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext';
// import BPHeader from './TPCOMPONENTS/bp-header'
// import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { StudentActions } from './TPCOMPONENTS/tp-reusables'
function TPGrades() {
  const {CurrentUserData} = useContext(UserAuthenticationContext)
  const [sortingView, setSortingView] = useState(false)
        function toggleSortingView (){
                setSortingView(!sortingView)
        }

        const { Progress, AlertNotice } = useContext(UserInterfaceContext);

        const [stage, setStage] = useState(CurrentUserData?.stage);
        const [subject, setSubject] = useState('')
        const [allSubjects, setAllSubjects] = useState([])
        const [studentData, setStudentData] = useState([]);
        const [scores, setScores] = useState([]);
        const [refresh, setRefresh] = useState(false)



        useEffect(() => {
          if(stage===''){
            return
          }
          Progress('30%');
          axios
            .get('https://new-softesc-backend.onrender.com/students', {
              params: {
                stage: stage,
              },
            })
            .then((response) => {
              Progress('100%');
              ////console.log(response.data);
              setStudentData(response.data);
              setScores(response.data.map(student => ({
                refNumber: student.refNumber,
                examScore: '',
                classScore: ''
              })));

            })
            .catch((error) => {
              Progress('0%');
              //console.log(error);
            });
        // }
      }, [stage]);


      useEffect(()=>{
       //change the the values to empty 
       setScores(studentData.map(student => ({
        refNumber: student.refNumber,
        examScore: '',
        classScore: ''
      })));
      },[subject])

 // Update exam score for a student
  const updateExamScore = (refNumber, value) => {
    setScores(scores.map(score => {
      if (score.refNumber === refNumber) {
        return { ...score, examScore: value };
      }
      return score;
    }));
  };

  // Update class score for a student
  const updateClassScore = (refNumber, value) => {
    setScores(scores.map(score => {
      if (score.refNumber === refNumber) {
        return { ...score, classScore: value };
      }
      return score;
    }));
  };

  
  // Function to add grades for a student
  const AddGrades = (selectedStudent, examScore, classScore) => {
    const score = scores.find(score => score.refNumber === selectedStudent);

    if (score) {
      const { examScore, classScore, ...rest } = score;
      
      const ReportDetails = {
        // ...rest,
        student : selectedStudent,
        stage: stage,
        subject: subject,
        examScore: examScore,
        classScore: classScore,
      };
      //console.log(ReportDetails);
      if (ReportDetails.examScore ===''||ReportDetails.classScore===''|| ReportDetails.subject===''){
        AlertNotice('Empty Subject, Exam Score and Class Score', false)
        return
      }
      else{
        if (ReportDetails.examScore >50||ReportDetails.classScore>50|| ReportDetails.examScore <0||ReportDetails.classScore<0){
          AlertNotice('Exam or Class Score > 50 or < 0', false)
          return
        }
        else{
          handleSubmitGrades(ReportDetails);

        }

      }
    }
  };

  //Function to fetch subjects for respective classes 
  async function FetchSubjects(){
    if (stage===''){
      return
    }
    await axios.get('https://new-softesc-backend.onrender.com/subject', 
    {
      params : {
        stage: stage,
        subject : subject,
      }
    })
    .then((response)=>{
      setAllSubjects(response.data)
      ////console.log(response.data)
    })
    .catch((error)=>{
      //console.log(error)
    })
  }

  useEffect(()=>{
    setSubject('')
    FetchSubjects()
    setRefresh(false)
  },[stage])


 
// Function to submit grades
  const handleSubmitGrades = (ReportDetails) => {
    Progress('30%')
    axios.post('https://new-softesc-backend.onrender.com/grades', ReportDetails)
      .then((response) => {
        AlertNotice(response.data.message, true)
        Progress('100%')
        // setScores(studentData.map(student => ({
        //   refNumber: student.refNumber,
        //   examScore: '',
        //   classScore: ''
        // })));
      })
      .catch((error) => {
        AlertNotice(error.response.data.message, true)
        Progress('0%')
        //console.log(error);
      });
  };




  return (
    <div className='App'>
      <TPSideMenu/>
      <Alert/>

      <div className='main-menu'>
        <TPHeader/>
        <div  className=' grades content-loader'>
            {/* the main menu content goes here  */}
            <StudentActions/>
            <div className="grades-header">
                <div className="sorting">
                <div className="sorting-header" onClick={toggleSortingView}>
                        <h2>Select Class and Subject</h2>
                        <IoIosArrowDown id='sorting-down-arrow'/>
                </div>                
                <div className='sorting-body'style={{display: sortingView?'none':'flex'}}>
                    {/* <div className="input">
                    <label htmlFor="">Class: </label>
                    <select 
                        id="mySelect"
                        required
                        value={stage}
                        onChange={(e) => {setStage(e.target.value)}                }                      
                      >
                        <option value="" disabled hidden>Select a Class </option>
                        <option value={-4}>Preschool - Creche</option>
                        <option value={-3}>Preschool - Nursery One</option>
                        <option value={-2}>Preschool - Nursery Two</option>
                        <option value={-1}>Kindergarten One</option>
                        <option value={0}>Kindergarten Two</option>
                        <option value={1}>Class One</option>
                        <option value={2}>Class Two</option>
                        <option value={3}>Class Three</option>
                        <option value={4}>Class Four</option>
                        <option value={5}>Class Five</option>
                        <option value={6}>Class Six</option>
                        <option value={7}>Class Seven/ JHS 1</option>
                        <option value={8}>Class Eight / JHS 2</option>
                        <option value={9}>Class Nine / JHS 3</option>
                    </select>
                    </div> */}
                    

                    {
                    stage>-4 && stage <=-2?
                    <div className="input">
                    <label htmlFor="">Student: </label>
                    <select 
                        id="mySelect"
                        value={subject}
                        onChange={(e)=>{setSubject(e.target.value)}}
                        >
                        <option value='' disabled hidden>Select a Student </option>
                          {
                            allSubjects?allSubjects.map((subject, index)=>(
                              <option key={index} value={subject.refNumber}> {subject.subjectName} </option>
                            )): null
                          }                       
                    </select>
                    </div> : null
                   }


                    <div className="input">
                    <label htmlFor="">Subject: </label>
                    <select 
                        id="mySelect"
                        value={subject}
                        onChange={(e)=>{setSubject(e.target.value)}}
                        >
                        <option value='' disabled hidden>Choose a subject</option>
                          {
                            allSubjects?allSubjects.map((subject, index)=>(
                              <option key={index} value={subject.refNumber}> {subject.subjectName} </option>
                            )): null
                          }                       
                    </select>
                    </div>
                   
                </div>
                </div>
                {/* <form action=""> */}
                
                {stage>-2?<div className="entry-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Roll</th>
                                <th>Ref Number</th>
                                <th id='user-name-head'>Name</th>
                                <th>Exam Score (50%)</th>
                                <th>Class Score (50%)</th>
                                <th>Grade Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {studentData.length > 0 ? (
                  studentData.map((student, index) => (
                    <tr id="tr" key={index}>
                      <td>{index + 1}</td>
                      <td>{student.refNumber}</td>
                      <td id="user-name">{student.firstName + ' ' + student.middleName + ' ' + student.lastName}</td>
                      <td><input type="Number" value={scores.find(score => score.refNumber === student.refNumber)?.examScore} onChange={(e) => updateExamScore(student.refNumber, e.target.value)} /></td>
                          <td><input type="Number" value={scores.find(score => score.refNumber === student.refNumber)?.classScore} onChange={(e) => updateClassScore(student.refNumber, e.target.value)} /></td>
                      <td id="attendance-checkbox">
                      <button id='present' style={{ backgroundColor: 'green', marginInline: 'auto', width: '90%' }} onClick={() => { AddGrades(student.refNumber, scores.find(score => score.refNumber === student.refNumber)?.examScore, scores.find(score => score.refNumber === student.refNumber)?.classScore) }}>ADD</button>

                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{padding:'1rem', color:'red'}}>No students available in selected Class or You have not selected a class</td>
                  </tr>
                )}
                        </tbody>
                    </table>
                </div>: 
                <div className="pre-school-grade-pad">

                      {/* LANGUAGE AND COMMUNICATION  */}
                     {
                      subject === 'LAN-01-3'||subject === 'LAN-02-2'? 
                      <table>
                      <thead >
                        <th className='grade-header' colSpan={5}>LANGUAGE AND COMMUNICATION</th>                       
                      </thead>
                      <thead>
                          <th>ACTIVITY</th>
                          <th>A</th>
                          <th>S</th>
                          <th>N</th>
                          <th>NEA</th>
                        
                      </thead>
                    
                      <tbody>
                          <tr>
                              <td>Can express him/her self clearly</td>
                              <td><input name='lang1' type="radio" /></td>
                              <td><input name='lang1' type="radio" /></td>
                              <td><input name='lang1' type="radio" /></td>
                              <td><input name='lang1' type="radio" /></td>
                              
                          </tr>
                          <tr>
                              <td>Can say / sing the alphabet</td>
                              <td><input name='lang2' type="radio" /></td>
                              <td><input name='lang2' type="radio" /></td>
                              <td><input name='lang2' type="radio" /></td>
                              <td><input name='lang2' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can read pictures</td>
                              <td><input name='lang3' type="radio" /></td>
                              <td><input name='lang3' type="radio" /></td>
                              <td><input name='lang3' type="radio" /></td>
                              <td><input name='lang3' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can identify objects in the home / school</td>
                              <td><input name='lang4' type="radio" /></td>
                              <td><input name='lang4' type="radio" /></td>
                              <td><input name='lang4' type="radio" /></td>
                              <td><input name='lang4' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can scribble/pre-writting patterns</td>
                              <td><input name='lang5' type="radio" /></td>
                              <td><input name='lang5' type="radio" /></td>
                              <td><input name='lang5' type="radio" /></td>
                              <td><input name='lang5' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can tell daily experiences</td>
                              <td><input name='lang6' type="radio" /></td>
                              <td><input name='lang6' type="radio" /></td>
                              <td><input name='lang6' type="radio" /></td>
                              <td><input name='lang6' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can say/act simple Rhymes</td>
                              <td><input name='lang7' type="radio" /></td>
                              <td><input name='lang7' type="radio" /></td>
                              <td><input name='lang7' type="radio" /></td>
                              <td><input name='lang7' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can write the letters</td>
                              <td><input name='lang8' type="radio" /></td>
                              <td><input name='lang8' type="radio" /></td>
                              <td><input name='lang8' type="radio" /></td>
                              <td><input name='lang8' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can read two/three letter words</td>
                              <td><input name='lang9' type="radio" /></td>
                              <td><input name='lang9' type="radio" /></td>
                              <td><input name='lang9' type="radio" /></td>
                              <td><input name='lang9' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can answer 'why' questions</td>
                              <td><input name='lang10' type="radio" /></td>
                              <td><input name='lang10' type="radio" /></td>
                              <td><input name='lang10' type="radio" /></td>
                              <td><input name='lang10' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can describe activity / objects </td>
                              <td><input name='lang11' type="radio" /></td>
                              <td><input name='lang11' type="radio" /></td>
                              <td><input name='lang11' type="radio" /></td>
                              <td><input name='lang11' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can recall parts of a story told/heard</td>
                              <td><input name='lang12' type="radio" /></td>
                              <td><input name='lang12' type="radio" /></td>
                              <td><input name='lang12' type="radio" /></td>
                              <td><input name='lang12' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can ask questions</td>
                              <td><input name='lang13' type="radio" /></td>
                              <td><input name='lang13' type="radio" /></td>
                              <td><input name='lang13' type="radio" /></td>
                              <td><input name='lang13' type="radio" /></td>
                          </tr>
                          <tr id='left'>
                              <td>Can recall whole Story</td>
                              <td><input name='lang14' type="radio" /></td>
                              <td><input name='lang14' type="radio" /></td>
                              <td><input name='lang14' type="radio" /></td>
                              <td><input name='lang14' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can retell/tell sequentially </td>
                              <td><input name='lang15' type="radio" /></td>
                              <td><input name='lang15' type="radio" /></td>
                              <td><input name='lang15' type="radio" /></td>
                              <td><input name='lang15' type="radio" /></td>
                          </tr>
                        
                      </tbody>
                      </table> : null
                     }

                      {/* physical report  */}
                      {
                      subject === 'PHY-01-3'||subject === 'PHY-02-2'? 
                      <table>
                          <thead >
                            <th className='grade-header' colSpan={5}>PHYSICAL REPORT</th>                       
                          </thead>
                          <thead>
                              <th >ACTIVITY</th>
                              <th>A</th>
                              <th>S</th>
                              <th>N</th>
                              <th>NEA</th>
                            
                          </thead>
                        
                          <tbody>
                              <tr>
                                  <td>Can throw, catch and kick ball</td>
                                  <td><input name='phys1' type="radio" /></td>
                                  <td><input name='phys1' type="radio" /></td>
                                  <td><input name='phys1' type="radio" /></td>
                                  <td><input name='phys1' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Shows good physical co-ordination</td>
                                  <td><input name='phys2' type="radio" /></td>
                                  <td><input name='phys2' type="radio" /></td>
                                  <td><input name='phys2' type="radio" /></td>
                                  <td><input name='phys2' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Active and enjoys outdoor games</td>
                                  <td><input name='phys3' type="radio" /></td>
                                  <td><input name='phys3' type="radio" /></td>
                                  <td><input name='phys3' type="radio" /></td>
                                  <td><input name='phys3' type="radio" /></td>
                              </tr>
                                                  
                            
                            
                          </tbody>
                      </table> : null
                      }
                    
                    {/* HEALTH  */}
                     {
                        // subject === 'physical-report'? 
                        subject === 'HEA-01-3'||subject === 'HEA-02-2'? 
                      <table>
                      <thead >
                        <th className='grade-header' colSpan={5}>HEALTH</th>                       
                      </thead>
                      <thead>
                          <th>ACTIVITY</th>
                          <th>A</th>
                          <th>S</th>
                          <th>N</th>
                          <th>NEA</th>
                        
                      </thead>
                    
                      <tbody>
                          <tr>
                              <td>Attends toilet at acceptable times</td>
                              <td><input name='health1' type="radio" /></td>
                              <td><input name='health1' type="radio" /></td>
                              <td><input name='health1' type="radio" /></td>
                              <td><input name='health1' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Attends toilet at acceptabe places</td>
                              <td><input name='health2' type="radio" /></td>
                              <td><input name='health2' type="radio" /></td>
                              <td><input name='health2' type="radio" /></td>
                              <td><input name='health2' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can wash hands after toilet</td>
                              <td><input name='health3' type="radio" /></td>
                              <td><input name='health3' type="radio" /></td>
                              <td><input name='health3' type="radio" /></td>
                              <td><input name='health3' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can use the handkerchief correctly</td>
                              <td><input name='health4' type="radio" /></td>
                              <td><input name='health4' type="radio" /></td>
                              <td><input name='health4' type="radio" /></td>
                              <td><input name='health4' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Has good control of bladder</td>
                              <td><input name='health5' type="radio" /></td>
                              <td><input name='health5' type="radio" /></td>
                              <td><input name='health5' type="radio" /></td>
                              <td><input name='health5' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can indicate the toilet needs </td>
                              <td><input name='health6' type="radio" /></td>
                              <td><input name='health6' type="radio" /></td>
                              <td><input name='health6' type="radio" /></td>
                              <td><input name='health6' type="radio" /></td>
                          </tr>
                                    
                      </tbody>
                      </table> : null
                     }


                      {/* OUR WORLD OUR PEOPLE  */}
                      {
                        subject === 'OWO-01-3'||subject === 'OWO-02-2'? 
                        <table>
                          <thead >
                            <th className='grade-header' colSpan={5}>OUR WORLD OUR PEOPLE</th>                       
                          </thead>
                          <thead>
                              <th >ACTIVITY</th>
                              <th>A</th>
                              <th>S</th>
                              <th>N</th>
                              <th>NEA</th>
                            
                          </thead>
                        
                          <tbody>
                              <tr>
                                  <td>Can identify nature sounds / noises</td>
                                  <td><input name='owop1' type="radio" /></td>
                                  <td><input name='owop1' type="radio" /></td>
                                  <td><input name='owop1' type="radio" /></td>
                                  <td><input name='owop1' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>cCan identify familiar mechanical sounds</td>
                                  <td><input name='owop2' type="radio" /></td>
                                  <td><input name='owop2' type="radio" /></td>
                                  <td><input name='owop2' type="radio" /></td>
                                  <td><input name='owop2' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Can identify and name plants</td>
                                  <td><input name='owop3' type="radio" /></td>
                                  <td><input name='owop3' type="radio" /></td>
                                  <td><input name='owop3' type="radio" /></td>
                                  <td><input name='owop3' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Can identify and name animals</td>
                                  <td><input name='owop4' type="radio" /></td>
                                  <td><input name='owop4' type="radio" /></td>
                                  <td><input name='owop4' type="radio" /></td>
                                  <td><input name='owop4' type="radio" /></td>
                              </tr>
                                                                            
                          </tbody>
                        </table> : null
                      }



                      {/* CREATIVE ARTS   */}
                      {
                        subject === 'CRE-01-3'||subject === 'CRE-02-2'? 
                        <table>
                          <thead >
                            <th className='grade-header' colSpan={5}>CREATIVE ARTS</th>                       
                          </thead>
                          <thead>
                              <th >ACTIVITY</th>
                              <th>A</th>
                              <th>S</th>
                              <th>N</th>
                              <th>NEA</th>
                            
                          </thead>
                        
                          <tbody>
                              <tr>
                                  <td>Enjoys music / dancing</td>
                                  <td><input name='creative1' type="radio" /></td>
                                  <td><input name='creative1' type="radio" /></td>
                                  <td><input name='creative1' type="radio" /></td>
                                  <td><input name='creative1' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Enjoys painting, modelling</td>
                                  <td><input name='creative2' type="radio" /></td>
                                  <td><input name='creative2' type="radio" /></td>
                                  <td><input name='creative2' type="radio" /></td>
                                  <td><input name='creative2' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Can write patterns</td>
                                  <td><input name='creative3' type="radio" /></td>
                                  <td><input name='creative3' type="radio" /></td>
                                  <td><input name='creative3' type="radio" /></td>
                                  <td><input name='creative3' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Can make free hand draings of obhects</td>
                                  <td><input name='creative4' type="radio" /></td>
                                  <td><input name='creative4' type="radio" /></td>
                                  <td><input name='creative4' type="radio" /></td>
                                  <td><input name='creative4' type="radio" /></td>
                              </tr>
                              
                                                    
                          </tbody>
                        </table>: null
                      }


                      {/* OTHER CODNITIE ABILITTIES (PROBLEMS SOLVING / SELF HELP)  */}
                     {
                      subject === 'COG-01-3'||subject === 'COG-02-2'? 
                      <table>
                      <thead >
                        <th className='grade-header' colSpan={5}>OTHER COGNITIVE ABILITIES (PROBLEMS SOLVING / SELF HELP)</th>                       
                      </thead>
                      <thead>
                          <th>ACTIVITY</th>
                          <th>A</th>
                          <th>S</th>
                          <th>N</th>
                          <th>NEA</th>
                        
                      </thead>
                    
                      <tbody>
                          <tr>
                              <td>Can discover missing part of play things</td>
                              <td><input name='cognitive1' type="radio" /></td>
                              <td><input name='cognitive1' type="radio" /></td>
                              <td><input name='cognitive1' type="radio" /></td>
                              <td><input name='cognitive1' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can name some objects by touching</td>
                              <td><input name='cognitive2' type="radio" /></td>
                              <td><input name='cognitive2' type="radio" /></td>
                              <td><input name='cognitive2' type="radio" /></td>
                              <td><input name='cognitive2' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can associate sound with objects/ animals </td>
                              <td><input name='cognitive3' type="radio" /></td>
                              <td><input name='cognitive3' type="radio" /></td>
                              <td><input name='cognitive3' type="radio" /></td>
                              <td><input name='cognitive3' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can solve puzzles</td>
                              <td><input name='cognitive4' type="radio" /></td>
                              <td><input name='cognitive4' type="radio" /></td>
                              <td><input name='cognitive4' type="radio" /></td>
                              <td><input name='cognitive4' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can wear socks  / shoes</td>
                              <td><input name='cognitive5' type="radio" /></td>
                              <td><input name='cognitive5' type="radio" /></td>
                              <td><input name='cognitive5' type="radio" /></td>
                              <td><input name='cognitive5' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can express without assistance</td>
                              <td><input name='cognitive6' type="radio" /></td>
                              <td><input name='cognitive6' type="radio" /></td>
                              <td><input name='cognitive6' type="radio" /></td>
                              <td><input name='cognitive6' type="radio" /></td>
                          </tr>
                          <tr>
                              <td>Can eat tidely by himself / herself</td>
                              <td><input name='cognitive7' type="radio" /></td>
                              <td><input name='cognitive7' type="radio" /></td>
                              <td><input name='cognitive7' type="radio" /></td>
                              <td><input name='cognitive7' type="radio" /></td>
                          </tr>
                          
                                                
                      </tbody>
                      </table> : null
                     }


                      {/* MATHEMATICS  */}
                      {
                        subject === 'MAT-01-3'||subject === 'MAT-02-2'? 
                        <table>
                        <thead >
                          <th className='grade-header' colSpan={5}>MATHEMATICS</th>                       
                        </thead>
                        <thead>
                            <th>ACTIVITY</th>
                            <th>A</th>
                            <th>S</th>
                            <th>N</th>
                            <th>NEA</th>
                          
                        </thead>
                      
                        <tbody>
                            <tr>
                                  <td>Can say the numeral up to 10</td>
                                  <td><input name='math1' type="radio" /></td>
                                  <td><input name='math1' type="radio" /></td>
                                  <td><input name='math1' type="radio" /></td>
                                  <td><input name='math1' type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Can recognize and name basic shapes</td>
                               <td><input name='math2' type="radio" /></td>
                                  <td><input name='math2' type="radio" /></td>
                                  <td><input name='math2' type="radio" /></td>
                                  <td><input name='math2' type="radio" /></td>
                            </tr>
                            <tr>
                                <td>Can write the numerals</td>
                               <td><input name='math3' type="radio" /></td>
                                  <td><input name='math3' type="radio" /></td>
                                  <td><input name='math3' type="radio" /></td>
                                  <td><input name='math3' type="radio" /></td>
                            </tr>
                            
                                                  
                        </tbody>
                        </table>: null
                      }


                      {/* SENSES  */}
                      {
                        subject === 'SEN-01-3'||subject === 'SEN-02-2' ? 
                        <table>
                          <thead >
                            <th className='grade-header' colSpan={5}>SENSES</th>                       
                          </thead>
                          <thead>
                              <th >ACTIVITY</th>
                              <th>A</th>
                              <th>S</th>
                              <th>N</th>
                              <th>NEA</th>
                            
                          </thead>
                        
                          <tbody>
                              <tr>
                                  <td>Taste</td>
                                  <td><input name='sense1' type="radio" /></td>
                                  <td><input name='sense1' type="radio" /></td>
                                  <td><input name='sense1' type="radio" /></td>
                                  <td><input name='sense1' type="radio" /></td>
                              </tr>
                              <tr>
                                  <td>Smells</td>
                                  <td><input name='sense2' type="radio" /></td>
                                  <td><input name='sense2' type="radio" /></td>
                                  <td><input name='sense2' type="radio" /></td>
                                  <td><input name='sense2' type="radio" /></td>

                              </tr>
                              <tr>
                                 
                                  <td>Sounds</td>
                                  <td><input name='sense3' type="radio" /></td>
                                  <td><input name='sense3' type="radio" /></td>
                                  <td><input name='sense3' type="radio" /></td>
                                  <td><input name='sense3' type="radio" /></td>

                              </tr>
                              <tr>
                                 
                                  <td>Colours / Shapes</td>
                                  <td><input name='sense4' type="radio" /></td>
                                  <td><input name='sense4' type="radio" /></td>
                                  <td><input name='sense4' type="radio" /></td>
                                  <td><input name='sense4' type="radio" /></td>
                              </tr>
                              <tr>
                                  
                                  <td>Textures</td>
                                  <td><input name='sense5' type="radio" /></td>
                                  <td><input name='sense5' type="radio" /></td>
                                  <td><input name='sense5' type="radio" /></td>
                                  <td><input name='sense5' type="radio" /></td>

                              </tr>
                                                    
                          </tbody>
                        </table>: null
                      }



                  </div>

                }
                
                
                {/* </form> */}
            </div>
        </div>

      </div>
    </div>
  )
}

export default TPGrades
