import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
// import {MdPlaylistAddCheck}
import { FaBars,FaArrowDown,FaWhatsapp,FaTachometerAlt, FaUserGraduate, FaUser,FaUsers, FaBook,FaUtensils,FaEnvelope, FaUserAlt, FaChalkboard,FaDollarSign, FaBell, FaCog, FaSignOutAlt, FaBox, FaClipboardCheck } from 'react-icons/fa';
import '../../Components/components.css'
import logo from '../../images/icons/School Crest black.png'
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../../Contexts/ui-context';


function TPSideMenu() {
    const {showSideMenu, setShowSideMenu, toggleSideMenu} = useContext(UserInterfaceContext)
  const [isSideMenuDisplay, setSideMenuDisplay] = useState(null)

  
  return (
    <div className='side-menu' style={showSideMenu?{width: '4rem',minWidth: '4rem'}:{width: '20rem', minWidth: '20rem'}}>
      
      <div id="header"> 
          <div>
            {showSideMenu?<FaBars id='side-menu-FaBars' onClick={toggleSideMenu}/>:
            <div id="logo" onClick={toggleSideMenu}>
            <img src={logo} alt="" />
            </div> }
            <h2>SOFTESC </h2>
          </div> <FaBars id='side-menu-FaBars' onClick={toggleSideMenu}/>
      </div>
      <div className='sideNavLinks'>
      <ul className='nav'>
      
      <NavLink id='Link' className='Link' to='/TeachersDashboard'>  <div className='side-menu-item'><FaTachometerAlt id='side-menu-icon'/><li>Dashboard</li></div><IoIosArrowDown id='side-menu-down-arrow'/> </NavLink>
      <NavLink id='Link' className='Link' to='/TPStudents'>  <div className='side-menu-item'><FaUserGraduate id='side-menu-icon'/><li>Students</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink>
      {/* <NavLink id='Link' className='Link' to='/Grades'>  <div className='side-menu-item'><FaClipboardCheck id='side-menu-icon'/><li>Grades</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/AddStudent'>  <div className='side-menu-item'><FaUserPlus id='side-menu-icon'/><li>Admission</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/AllTeachers'>  <div className='side-menu-item'><FaUser id='side-menu-icon'/><li>Staff</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/AllParents'>  <div className='side-menu-item'><FaUsers id='side-menu-icon'/><li>Parents</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/FeeCollection'> <div className='side-menu-item'><FaDollarSign id='side-menu-icon'/><li>Finance</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/AllClasses'>  <div className='side-menu-item'><FaChalkboard id='side-menu-icon'/><li>Class Room</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      <NavLink id='Link' className='Link' to='/TPMessages'> <div className='side-menu-item'> <FaEnvelope id='side-menu-icon'/><li>Notices & Messages</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink>

      {/* <NavLink id='Link' className='Link' to='/attendance'>  <div className='side-menu-item'><MdPlaylistAddCheck id='side-menu-icon' /><li>Attendance</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/Inventory'>  <div className='side-menu-item'><FaBox id='side-menu-icon'/><li>Inventory</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
      {/* <NavLink id='Link' className='Link' to='/AllBooks'>  <div className='side-menu-item'><FaBook id='side-menu-icon'/><li>Library</li> </div><IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}

      {/* <NavLink id='Link' className='Link' to='/Feeding'>  <div className='side-menu-item'><FaUtensils id='side-menu-icon'/><li>Feeding</li></div> <IoIosArrowDown id='side-menu-down-arrow'/></NavLink> */}
    </ul>
    
    
    <ul className='nav'>
      {/* <NavLink id='Link' className='Link' to='/Settings'><div className='side-menu-item'> <FaCog  id='side-menu-icon'/><li>Settings</li></div></NavLink> */}
      <NavLink id='Link' className='Link' to='/Logout' ><div className='side-menu-item'> <FaSignOutAlt id='side-menu-icon'/><li>Logout</li></div></NavLink>
    
    </ul>
    </div>
  </div>  )
}

export default TPSideMenu