import React, { useContext, useEffect, useState } from 'react';
import './attendance.css';
import { StudentActions } from '../../Components/reusables';
import {SideMenu} from '../../Components/sideMenu';
import Header from '../../Components/header';
import { IoIosArrowDown } from 'react-icons/io';
import axios from 'axios';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { Alert } from '../../Components/reusables';
import { Loading } from '../../Components/reusables';

function Attendance() {

  const [sortingView, setSortingView] = useState(false);
  const toggleSortingView = () => setSortingView(!sortingView);

  const { Progress, AlertNotice, setLoading } = useContext(UserInterfaceContext);
  const [studentData, setStudentData] = useState([]);
  const [stage, setStage] = useState('');
  const [attendanceDate, setAttendanceDate] = useState(new Date().toISOString().split('T')[0]);
  const [userId, setUserId] = useState('')
  const [isPresent, setIsPresent] = useState()
  // const [attendanceCounter, setAttendanceCounter] = useState(0)
  const [attendanceList, setAttendanceList] = useState([])
  const [attendanceData, setAttendanceData] = useState({})



  useEffect(() => {
    if (stage !== '') {
      Progress('30%');
      setLoading(true)

      axios
        .get('https://new-softesc-backend.onrender.com/students', {
          params: { stage: stage },
        })
        .then((response) => {
          setLoading(false)
          Progress('100%');
          // ////console.log(response.data);
          setStudentData(response.data);
        })
        .catch((error) => {
          Progress('0%');
          //console.log(error);
        });
    }
  }, [stage]);

  
  function HandleSubmitAttendance(attendanceData){
    setAttendanceData(attendanceData)
    if(!attendanceDate){
      AlertNotice('Add Attendance Date', false)
      return
    }
    Progress('30%')
    axios.post('https://new-softesc-backend.onrender.com/attendance', attendanceData)
      .then((response) => {
        Progress('100%')
        AlertNotice(response.data.message, true)
        if (attendanceData.isPresent === false) {
          setAttendanceList(prevList => prevList.filter(id => id !== attendanceData.userId));
        } else {
          UpdateAttendanceList();
        }
      })
      .catch((error) => {
        Progress('0%')
        //console.log(error);
        AlertNotice(error.response.data.error, false)
      });
  };

  function UpdateAttendanceList() {
    setAttendanceList(prevList => [...prevList, userId]);
  }

  function CheckAttendance (a, b){
    setUserId(a)
    setIsPresent(b)
    const attendanceData = {
      stage: stage,
      userId: a,
      attendanceDate: attendanceDate,
      isPresent: b,
    };

    HandleSubmitAttendance(attendanceData)
    
}



  useEffect((userId, isPresent)=>{
    if(userId&&isPresent){
      CheckAttendance(userId, isPresent)
    }
    else{
      // //console.log('some required values are not in the attendance data')
    }
   },[userId,isPresent])


//    useEffect(()=>{
//     //console.log(attendanceList)
// },[attendanceList])

// useEffect(()=>{
//     //console.log(attendanceCounter)
// },[attendanceCounter])


  return (
    <div className="App">
      <SideMenu />
      <Alert/>
      <div className="main-menu">
        <Header />
        <div className="attendance content-loader">
          <StudentActions />
          <div className="sorting">
            <div className="sorting-header" onClick={toggleSortingView}>
              <h2>Select Class and Date</h2>
              <IoIosArrowDown id="sorting-down-arrow" />
            </div>
            <div className="sorting-body" style={{ display: sortingView ? 'none' : 'flex' }}>
              <div className="input">
                <label htmlFor="">Class: </label>
                <select
                  id="mySelect"
                  required
                  value={stage}
                  onChange={(e) => {setStage(e.target.value)}                }>
                        <option value='' disabled hidden>Select a class eg. class 0</option>
                        <option value={-3}>Preschool - Nursery One</option>
                        <option value={-2}>Preschool - Nursery Two</option>
                        <option value={-1}>Kindergarten One</option>
                        <option value={0}>Kindergarten Two</option>
                        <option value={1}>Class One</option>
                        <option value={2}>Class Two</option>
                        <option value={3}>Class Three</option>
                        <option value={4}>Class Four</option>
                        <option value={5}>Class Five</option>
                        <option value={6}>Class Six</option>
                        <option value={7}>Class Seven/ JHS 1</option>
                        <option value={8}>Class Eight / JHS 2</option>
                        <option value={9}>Class Nine / JHS 3</option>
                </select>
              </div>

              <div className="input">
                <label htmlFor="">Date:</label>
                <input type="date" 
                value={attendanceDate} 
                onChange={(e) => setAttendanceDate(e.target.value)} />
              </div>
            </div>
          </div>

          {/* Attendance progress  */}
        

          <div className="container">
            <table className="user-table">
              <Loading/>
              <thead>
                <tr>
                  <th>Roll</th>
                  <th>Student Id</th>
                  <th id="user-name-head">Name</th>
                  <th>Mark Attendance</th>
                </tr>
              </thead>
              <tbody>
                {studentData.length > 0 ? (
                  studentData.map((student, index) => (
                    <tr id="tr" key={index}>
                      <td>{index + 1}</td>
                      <td>{student.refNumber}</td>
                      <td id="user-name" style={attendanceList.includes(student.refNumber)?{ color: 'yellow'}:{color :'white'}}>{student.firstName + ' ' + student.middleName + ' ' + student.lastName}</td>
                      <td id="attendance-checkbox">
                        <button id='present' style={{backgroundColor: 'green'}} onClick={()=>{CheckAttendance(student.refNumber, true)}}>Present</button>
                        <button id='absent' style={{backgroundColor: 'red'}} onClick={()=>{CheckAttendance(student.refNumber, false)}}>Absent</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{padding:'1rem', color:'red'}}>No students available in selected Class or You have not selected a class</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Attendance;
