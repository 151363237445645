import React, { useContext, useState } from 'react'
import './user.css'
import { StudentActions, Alert } from '../../Components/reusables'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import axios from 'axios'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import { UserDataContext } from '../../Contexts/user-data'
import { Navigate } from 'react-router-dom'

function StudentUpdate() {
  const {Progress, AlertNotice} = useContext(UserInterfaceContext)
  const {userId} = useContext(UserDataContext)
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otherNames, setOtherNames] = useState('');
  const [classValue, setClassValue] = useState();
  const [house, setHouse] = useState();
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [fatherName, setFatherName] = useState('');
  const [motherName, setMotherName] = useState('');
  const [fatherOccupation, setFatherOccupation] = useState('');
  const [motherOccupation, setMotherOccupation] = useState('');
  const [fatherNationality, setFatherNationality] = useState('');
  const [motherNationality, setMotherNationality] = useState('');
  const [fatherPhone, setFatherPhone] = useState('');
  const [motherPhone, setMotherPhone] = useState('');
  const [email, setEmail] = useState('');
  const [presentAddress, setPresentAddress] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [digitalAddress, setDigitalAddress] = useState('');
  
  if(!userId){
    return <Navigate  to= '/AllStudent'/>
  }

const resetValues= (e)=>{
  setFirstName('')
  setMiddleName('')
  setLastName('')
  setOtherNames('')
  setClassValue('')
  setHouse('')
  setGender('')
  setDob('')
  setFatherName('')
  setMotherName('')
  setFatherOccupation('')
  setMotherOccupation('')
  setFatherNationality('')
  setMotherNationality('')
  setFatherPhone('')
  setMotherPhone('')
  setEmail('')
  setPresentAddress('')
  setPermanentAddress('')
  setDigitalAddress('')

}

const studentData = {
  firstName : firstName,
  middleName : middleName,
  lastName : lastName,
  otherNames : otherNames,
  stage : classValue,
  house : house,
  gender : gender,
  dob : dob,
  fatherName : fatherName,
  motherName : motherName,
  fatherOccupation: fatherOccupation,
  motherOccupation : motherOccupation, 
  fatherNationality : fatherNationality,
  motherNationality : motherNationality,
  fatherPhone : fatherPhone ,
  motherPhone : motherPhone,
  email : email,
  presentAddress : presentAddress,
  permanentAddress : permanentAddress,
  digitalAddress : digitalAddress,
}

const filteredStudentData = Object.entries(studentData).reduce((acc, [key, value]) => {
  if (value !== null && value !== '') {
    acc[key] = value;
  }
  return acc;
}, {});


function HandleStudentUpdateSubmit(){
  Progress('30%')
  //console.log('processing submit')
  // axios.post('https://new-softesc-backend.onrender.com/students', studentData)
  axios.put(`https://new-softesc-backend.onrender.com/students/${userId}`, filteredStudentData)
  .then((response)=>{
    AlertNotice(response.data.message, true)
    Progress('100%')
  })
  .catch((error)=>{
    AlertNotice('Failed to Update Student', false)
    //console.log(error)
    Progress('0%')
  })

}

  return (
    <div className="App">
        <SideMenu/>
        <Alert/>
        <div className="main-menu">
          <Header/>
          <div className='add-student content-loader'>
      <StudentActions/>
      <div className="add-student-form">
          {/* <form onSubmit={handleSubmitAdmission}> */}
          <h2 className='class-level'>Update Student with ID : <b style={{color: 'yellow'}}>{userId}</b></h2>
          <br />
          <h2>Student Information</h2>
            <div className='student-information sorting'>
            <div className="sorting-body">
            <div className='input'>
              <label htmlFor="">First Name :</label>
              <input 
                name='firstName'
                type="text" 
                placeholder='eg. John'
                // required
                value={firstName}
                onChange={(e) => {setFirstName(e.target.value)}}
                />
            </div>
            
            <div className='input'>
              <label htmlFor="">Middle Name :</label>
              <input 
                type="text" 
                placeholder='eg. Kofi'
                value={middleName}
                onChange={(e) => {setMiddleName(e.target.value)}}
                
              
              />
            </div>

            <div className='input'>
              <label htmlFor="">Last Name :</label>
              <input 
                type="text" 
                placeholder='eg. Osei'
                // required
                value={lastName}
                onChange={(e) =>{ setLastName(e.target.value)}}
              />
            </div>
           <div className='input'>
              <label htmlFor="">Other Names :</label>
              <input 
                type="text" 
                placeholder='eg. Appiah'
                value={otherNames}
                onChange={(e) => {setOtherNames(e.target.value)}}
              />
           </div>

           <div className='input'>
              <label htmlFor="class">Class :</label>
              <select
                name="class"
                id="class"
                disabled
                value={classValue}
                onChange={(e)=>{setClassValue(e.target.value)}}
                // required
              >
                <option value="" disabled hidden> Please select Class  </option>
                <option value={-4}>Preschool - Creche</option>
                <option value={-3}>Preschool - Nursery One</option>
                <option value={-2}>Preschool - Nursery Two</option>
                <option value={-1}>Kindergarten One</option>
                <option value={0}>Kindergarten Two</option>
                <option value={1}>Class One</option>
                <option value={2}>Class Two</option>
                <option value={3}>Class Three</option>
                <option value={4}>Class Four</option>
                <option value={5}>Class Five</option>
                <option value={6}>Class Six</option>
                <option value={7}>Class Seven/ JHS 1</option>
                <option value={8}>Class Eight / JHS 2</option>
                <option value={9}>Class Nine / JHS 3</option>
              </select>
            </div>
            
            
            <div className='input'>
              <label htmlFor="">House:</label>
              <select 
                name="section" 
                id="section" 
                // required
                // disabled
                value={house}
                onChange={(e) => {setHouse(e.target.value)}}
                
                >
                <option value="" disabled hidden>Please select House / Section</option>
                <option value="A">House A </option>
                <option value="B">House B</option>
                <option value="C">House C </option>
                <option value="D">House D</option>
              </select>
            </div>
         
            <div className='input'>
                <label htmlFor="">Gender :</label>
                <select 
                  name="gender" 
                  // required
                  disabled
                  id="gender" 
                  defaultValue=""
                  value={gender}
                  onChange={(e) => {setGender(e.target.value)}}
                  >
                  <option value="" disabled hidden>Please select Gender </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
            </div>

            <div className='input'>
                <label htmlFor="">Date of Birth :</label>
                <input 
                name='dob'
                type="date" 
                // required
                value={dob}
                onChange={(e) => {setDob(e.target.value)}}
                />
            </div>
            </div>

            
            {/* <div className='input'>
              <label htmlFor="">Upload Student Photo :</label>
              <input type="file" name="" 
              style={{border: '1px solid var(--primary-color-blue)', paddingTop: '0.3rem'}}/>
            </div> */}
            </div>

            <h2>Parent / Guardian Information</h2>
            <div className='parent-information sorting'>
            <div className="sorting-body">
            <div className='input'>
              <label htmlFor="">Father's Name :</label>
              <input 
              name='fathername'
              type="text"  
              // required
              placeholder= "Enter Father's Name"
              value={fatherName}
                onChange={(e) => {setFatherName(e.target.value)}}
              />
            </div>
            
            <div className='input'>
              <label htmlFor="">Father's Nationality :</label>
              <input 
              name='fathernationality'
              type="text" 
              // required
              placeholder="Enter mother's occupation"
              value={fatherNationality}
                onChange={(e) => {setFatherNationality(e.target.value)}}
              />
            </div>
            
            <div className='input'>
              <label htmlFor="">Father's Occupation :</label>
              <input 
              name='fatheroccupation'
              type="text" 
              // required
              placeholder="Enter mother's occupation"
              value={fatherOccupation}
                onChange={(e) => {setFatherOccupation(e.target.value)}}
              />
            </div>

            <div className='input'>
              <label htmlFor="">Mother's Name :</label>
              <input 
              name='motohername'
              type="text" 
              // required
              placeholder= "Enter Mother's Name"
              value={motherName}
              onChange={(e) => {setMotherName(e.target.value)}}
              />
            </div>

            
            <div className='input'>
              <label htmlFor="">Mother Occupation :</label>
              <input 
              name='motheroccupation'
              type="text" 
              // required
              placeholder="Enter mother's occupation"
              value={motherOccupation}
                onChange={(e) => {setMotherOccupation(e.target.value)}}
              />
            </div>
            
            <div className='input'>
              <label htmlFor="">Mother's Nationality :</label>
              <input 
              name='mothernationality'
              type="text" 
              // required
              placeholder="Enter mother's occupation"
              value={motherNationality}
                onChange={(e) => {setMotherNationality(e.target.value)}}
              />
            </div>
            </div>

            </div>


            <h2>Contact Details</h2>
            <div className='contact-details sorting'>
            
              <div className="sorting-body">
              <div className='input'>
              <label htmlFor="">Father's Phone Number :</label>
                <input 
                type="tel" 
                name="fathephone" 
                // required
                placeholder='Main Active Phone one' 
                value={fatherPhone}
                onChange={(e) => {setFatherPhone(e.target.value)}}
                />
            </div>

            <div className='input'>
              <label htmlFor="">Mother's Phone Number :</label>
                <input 
                type="tel" 
                name="mothephone" 
                // required
                placeholder=' Main Active Phone one' 
                value={motherPhone}
                onChange={(e) => {setMotherPhone(e.target.value)}}
                />

            </div>
            

            <div className='input'>
              <label htmlFor="">Email :</label>
              <input 
              type="email"  
              name='email'
              // required
              placeholder='me@something.com'
              value={email}
              onChange={(e) => {setEmail(e.target.value)}}
              />
            </div>


            <div className='input'>
              <label htmlFor="">Present Address :</label>
              <input 
              type="text"
              name="presentaddress" 
              // id="" 
              // required
              placeholder='Enter your curreent residence address'
              value={presentAddress}
                onChange={(e) => {setPresentAddress(e.target.value)}}
              />
            </div>

            <div className='input'>
              <label htmlFor="">Permanent Address :</label>
              <input 
              type="text" 
              name="permanentaddress" 
              // id="" 
              // required
              placeholder='Enter your permanent Address'
              value={permanentAddress}
                onChange={(e) => {setPermanentAddress(e.target.value)}}
              />
            </div>
            
            <div className='input'>
              <label htmlFor="">Digital Address :</label>
              <input 
              type="text" 
              name="digitaladdress" 
              // id="" 
              placeholder='Enter your permanent Address'
              value={digitalAddress}
                onChange={(e) => {setDigitalAddress(e.target.value)}}
              />
            </div>
              </div>
            </div>

            <div id='submit-reset-buttons'>
              <button onClick={HandleStudentUpdateSubmit}>Update Student</button>
              <button onClick={resetValues}>Reset Inputs</button>
            </div>

          {/* </form> */}
      </div>

    </div>
        </div>
      </div>
    
  )
}

export default StudentUpdate