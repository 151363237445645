import React, { useContext, useState } from 'react'
import Header from '../../Components/header'
import {SideMenu} from '../../Components/sideMenu'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import { InventoryActions } from '../../Components/reusables'
import { IoIosArrowDown } from 'react-icons/io'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome

function Furniture() {
  const {setPageName} = useContext(UserInterfaceContext)
  setPageName('INVENTORY')

  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }
  return (
    <div className="App">
      <SideMenu/>
      <div className="main-menu">
        <Header/>
        <div className="content-loader">
          <InventoryActions/>
          <div className="sorting">
              <div className="sorting-header" onClick={toggleSortingView}>
                <h2>Sort By:</h2>
                <IoIosArrowDown id='sorting-down-arrow'/>

              </div>
              <div className="sorting-body" style={{display: sortingView?'none':'flex'}}>

              <div className="input">
                  <label htmlFor="">Name : </label>
                  <input type="text" />
                </div>

                <div className="input">
                  <label htmlFor="">Category : </label>
                  <select name="" id="">
                    <option value="" hidden disabled>Select a category</option>
                    <option value="a" >All</option>
                    <option value="a" >Computers</option>
                    <option value="b" >Books</option>
                    <option value="c" >Uniforms</option>
                    <option value="d" >Furniture</option>
                    <option value="d" >Others</option>
                   
                   
                  </select>
                </div>

                <div className="input">
                  <label htmlFor="">Condition : </label>
                    <select name="" id="">
                    <option value="" hidden disabled>Select a condition</option>
                    <option value="" >All </option>
                    <option value="d" >Good state </option>
                    <option value="h" >Faulty</option>
                    <option value="e" >Away for maintainence</option>
                    </select>
                </div>
                
               
              </div>
            </div>
          {/* <h2>The INVENTORY is still under construction</h2> */}
          <div
            class="table-responsive"
          >
            <table
              class="table table-primary"
            >
              <thead>
                <tr>
                  <th scope="col">Inventory ID</th>
                  <th scope="col" id='user-name-head'>Category</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Condition</th>
                  <th scope="col">Quantity</th>
                  <th id='user-actions-head'>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="">
                  <td scope="row">R1C1</td>
                  <td id='user-name'>R1C2</td>
                  <td>R1C3</td>
                  <td>R1C3</td>
                  <td>R1C4</td>
                  <td id='table-actions'><FaEye id='action-see'/> <FaEdit/> <FaTrash id='action-delete'/></td>
                </tr>
               
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Furniture