import React, { useEffect, useState,useContext } from 'react'
import { FaUserGraduate, FaUser,FaUsers,} from 'react-icons/fa';
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import axios from 'axios';
// import {  } from 'react';
import { UserDataContext } from '../../Contexts/user-data';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { Navigate } from 'react-router-dom';

function TeacherDetails() {
  const {Progress} = useContext(UserInterfaceContext)

  const {userId, } = useContext(UserDataContext)
  const [userData, setUserData] = useState([])
  
 
  useEffect(()=>{
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/staff',{
      params:{
        userId : userId,
      }
    })
  .then((response)=>{
    Progress('100%')
    setUserData(response.data)
    ////console.log(response.data)
    //console.log('userdata',userData)
  })
  .catch((error)=>{
    Progress('0%')
    //console.log(error)
  })
  },[userId])


  if(userId===''){
    return <Navigate to='/AllTeachers'/>
  }

  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('CLASS 1')
    }
    else if(stage===2){
        return('CLASS 2')
    }
    else if(stage===3){
        return('CLASS 3')
    }
    else if(stage===4){
        return('CLASS 4')
    }
    else if(stage===5){
        return('CLASS 5')
    }
    else if(stage===6){
        return('CLASS 6')
    }
    else if(stage===7){
        return('CLASS 7 / JHS 1')
    }
    else if(stage===8){
        return('CLASS 8 / JHS 2')
    }
    else if(stage===9){
        return('CLASS 9 / JHS 3')
    }
  }
  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <div className='teachers-details '>
      <h3 className='class-level'>TEACHERS DETAILS : <b style={{color: 'yellow'}}>{userId}</b> </h3>
      <div className="info">
            <div className="img-container">
              <FaUserGraduate id='user-details-icon'/>
            </div>
            <div className="personal-info">
              <label htmlFor="">Staff ID : {userData[0]?.refNumber} </label>
              <label htmlFor="">Name : {userData[0]?.firstName +' ' + userData[0]?.middleName +' ' + userData[0]?.lastName } </label>
              <label htmlFor="">{GenerateStageName(userData[0]?.stage)} </label>

            </div>
            </div>
      {userData.length>1?<p>Select Teacher from teachers data</p>:
          userData.map((userData, index)=>(
            <div className="info" key={index}>
            <div className="personal-info">
                <h3>Personally Information</h3>
                {/* <label htmlFor="">Staff Id: {userData.refNumber} </label> */}
                {/* <label htmlFor="">Name : {userData.firstName +' '+userData.middleName+ ' '+userData.lastName} </label> */}
                {/* <label htmlFor="">Class : {userData.stage} </label> */}
                <label htmlFor="">Subjects Thought : {userData.subject}  </label>
                <label htmlFor="">Date of Birt : {userData.dob} </label>
                <label htmlFor="">Nationality : {userData.nationality} </label>
                <label htmlFor="">Gender : {userData.gender} </label>
                <label htmlFor="">Address : {userData.address}  </label>
                <label htmlFor="">Telephone : {userData.telephone} </label>
                <label htmlFor="">Email : {userData.email} </label>
                <label htmlFor="">National Id Number (GH-CARD) : {userData.nationalIdNumber} </label>
            </div>
      
            <div className="financial-info">
                <h3>Financial Information</h3>
                <label htmlFor="">Bank Account Information </label>
                <label htmlFor="">Bank Name : {userData.bankName} </label>
                <label htmlFor="">Branch : {userData.branch} </label>
                <label htmlFor="">Account Number : {userData.accountNumber}  </label>
                <label htmlFor="">Salary : {userData.salary} </label>
                <label htmlFor="">Tax Identification Number : {userData.tin} </label>
                {/* <label htmlFor="">SNNIT Amount : {userData.snnitAmount} </label> */}
                {/* <label htmlFor="">GRE - TAX :  </label> */}
            </div>
      
            <div className="other-info">
                <h3>Other Info</h3>
                <label htmlFor="">Employment Date: {userData.dateOfEmployment} </label>
                <label htmlFor="">Staff Category: {userData.staffCategory} </label>
                <label htmlFor="">Status: Active </label>
                {/* <label htmlFor="">Name </label> */}
                {/* <label htmlFor="">Name </label> */}
            </div>
            </div>
          ))
      }


    </div>
        </div>
      </div>
    
  )
}

export default TeacherDetails