import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';


import {UserAuthenticationContext } from './Contexts/AuthenticationContext';
import { UserInterfaceProvider } from './Contexts/ui-context';
import { UserDataContextProviders } from './Contexts/user-data';
import { StageContextProvider } from './Contexts/stage-context';


//SUPER LEVEL IMPORTS 
import MainDashBoard from './Pages/owner/main-dash';
import SignIn from './Pages/SignIn';
import PageNotFound from './Pages/PageNotFound';
import AddExpense from './Pages/finance/add-expense';
import FeeCollection from './Pages/finance/fee-collection';
import AllIncome from './Pages/finance/all-income';
import AllExpenses from './Pages/finance/all-expenses';
import AddParent from './Pages/parents/add-parent';
import AllParents from './Pages/parents/all-parents';
import ParentDetails from './Pages/parents/parents-details';
import Attendance from './Pages/attendance/attendance';
import AddAcademicYear from './Pages/owner/add-academic-year';
import AddAcademicTerm from './Pages/owner/add-academic-term';
import AddClass from './Pages/owner/add-class';
import AddSubject from './Pages/owner/add-subject';
import AddStudent from './Pages/student/add-student';
import AllStudent from './Pages/student/all-student';
import StudentDetails from './Pages/student/student-details';
import StudentUpdate from './Pages/student/student-update';
import StudentPromotion from './Pages/student/student-promotion';
import AddTeacher from './Pages/staff/add-staff';
import AllTeachers from './Pages/staff/all-staff';
import TeacherDetails from './Pages/staff/teachers-details';
import AllClasses from './Pages/classes/all-classes';
import AllBooks from './Pages/library/all-books';
import AllNotices from './Pages/notice/all-notices';
import Inventory from './Pages/inventory/inventory';
import Uniforms from './Pages/inventory/uniforms';
import Books from './Pages/inventory/books';
import Furniture from './Pages/inventory/furniture';
import Computers from './Pages/inventory/computers';
import Feeding from './Pages/feeding/feeding';
import Settings from './Pages/Settings';
import Logout from './Components/Logout';
import Grades from './Pages/Grades/Grades';
import Classdetails from './Pages/classes/class-details';
import AddIncome from './Pages/finance/add-income';
import UpdateClass from './Pages/classes/update-class';
import AcademicReport from './Pages/student/academic-report';
import TeacherUpdate from './Pages/staff/update-staff';
 



//TEACHERS PORTAL IMPORTS 
import TeachersDashboard from './TEACHERS PORTAL/staff-dashboard.js';
import TPAttendance from './TEACHERS PORTAL/attendance.js';
import TPGenerateReport from './TEACHERS PORTAL/generateport.js';
import TPGrades from './TEACHERS PORTAL/grades.js';
import TPMessages from './TEACHERS PORTAL/messages.js';
import TPStudents from './TEACHERS PORTAL/students.js';
import TPStudentDetails from './TEACHERS PORTAL/student-details.js';

//BURSAR PORTAL IMPORTS
import BursarsDashBoard from './BURSAR PORTAL/bursar-dashboard.js';
import BPMessages from './BURSAR PORTAL/messages.js';
import BPFinance from './BURSAR PORTAL/finance.js';
import BPInventory from './BURSAR PORTAL/inventory.js';
import BPStudents from './BURSAR PORTAL/students.js';
import BPAddStudent from './BURSAR PORTAL/add-student.js';
import BPStudentDetails from './BURSAR PORTAL/student-details.js';
import BPAttendance from './BURSAR PORTAL/attendance.js';
import BPAddExpenses from './BURSAR PORTAL/add-expenses.js';
import BPAddIncome from './BURSAR PORTAL/add-income.js';
import BPAllExpenses from './BURSAR PORTAL/all-expenses.js';
import BPAllIncome from './BURSAR PORTAL/all-income.js';


//STUDENTS PORTAL IMPORTS 
import StudentDashboard from './STUDENTS PORTAL/student-dashboard.js';
import SPGrades from './STUDENTS PORTAL/grades.js';
import SPMessages from './STUDENTS PORTAL/message.js';
import SPFinance from './STUDENTS PORTAL/finance.js';
// import BPAddIncome from './BURSAR PORTAL/add-income.js';




function App() {

  return (
      <UserDataContextProviders>
          <StageContextProvider>
          <UserInterfaceProvider>
          <Router basename='/'>
              <Routes>
                  {/* <Route index element = {<SignIn/>}/> */}
                  <Route index element={
                      localStorage.getItem('loginStatus') === 'true' ? (
                        localStorage.getItem('userRole') === 'SUPREMO' ? (
                          <MainDashBoard />
                        ) : localStorage.getItem('userRole') === 'CLASS-TEACHER' ? (
                          <TeachersDashboard />
                        ) : localStorage.getItem('userRole') === 'ADMINISTRATION' ? (
                          <BursarsDashBoard />
                        ) : localStorage.getItem('userRole') === 'STUDENT' ? (
                          <StudentDashboard />
                        ) : (
                          <SignIn />
                        )
                      ) : (
                        <SignIn />
                      )
                    }/>
                    <Route path="/MainDashBoard" element={( localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO')? <MainDashBoard/>: <Navigate to="/" />} />
                    <Route path="/TeachersDashboard" element={( localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' )  ? <TeachersDashboard /> : <Navigate to="/" />} />
                    <Route path="/BursarsDashboard" element={( localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION') ? <BursarsDashBoard /> : <Navigate to="/" />} />
                    <Route path="/StudentsDashboard" element={( localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='STUDENT' ) ? <StudentDashboard /> : <Navigate to="/" />} />

                      {/* <Route path="/MainDashBoard" element={login ? <MainDashBoard /> : <Navigate to="/" />} /> */}
                      <Route path="/AddExpense" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddExpense /> : <Navigate to="/" />} />
                      <Route path="/FeeCollection" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <FeeCollection /> : <Navigate to="/" />} />
                      <Route path="/AddParent" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddParent /> : <Navigate to="/" />} />
                      <Route path="/AllParents" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllParents /> : <Navigate to="/" />} />
                      <Route path="/ParentDetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <ParentDetails /> : <Navigate to="/" />} />
                      <Route path="/Attendance" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Attendance /> : <Navigate to="/" />} />
                      <Route path="/AddStudent" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddStudent /> : <Navigate to="/" />} />
                      <Route path="/AllStudent" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllStudent /> : <Navigate to="/" />} />
                      <Route path="/StudentDetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <StudentDetails /> : <Navigate to="/" />} />
                      <Route path="/StudentUpdate" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <StudentUpdate /> : <Navigate to="/" />} />
                      <Route path="/StudentPromotion" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <StudentPromotion /> : <Navigate to="/" />} />
                      <Route path="/AddTeacher" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddTeacher /> : <Navigate to="/" />} />
                      <Route path="/AllTeachers" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllTeachers /> : <Navigate to="/" />} />
                      <Route path="/TeacherDetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <TeacherDetails /> : <Navigate to="/" />} />
                      <Route path="/TeacherUpdate" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <TeacherUpdate /> : <Navigate to="/" />} />
                      <Route path="/AllClasses" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllClasses /> : <Navigate to="/" />} />
                      <Route path="/AllBooks" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllBooks /> : <Navigate to="/" />} />
                      <Route path="/AllNotices" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllNotices /> : <Navigate to="/" />} />
                      <Route path="/Inventory" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Inventory /> : <Navigate to="/" />} />
                      <Route path="/Books" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Books /> : <Navigate to="/" />} />
                      <Route path="/Computers" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Computers /> : <Navigate to="/" />} />
                      <Route path="/Uniforms" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Uniforms /> : <Navigate to="/" />} />
                      <Route path="/Furniture" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Furniture /> : <Navigate to="/" />} />
                      <Route path="/Feeding" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Feeding /> : <Navigate to="/" />} />
                      <Route path="/Settings" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Settings /> : <Navigate to="/" />} />
                      <Route path="/Grades" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Grades /> : <Navigate to="/" />} />
                      <Route path="/Classdetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <Classdetails /> : <Navigate to="/" />} />
                      <Route path="/AddIncome" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddIncome /> : <Navigate to="/" />} />
                      <Route path="/AllIncome" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllIncome /> : <Navigate to="/" />} />
                      <Route path="/AllExpenses" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllExpenses /> : <Navigate to="/" />} />
                      <Route path="/UpdateClass" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <UpdateClass /> : <Navigate to="/" />} />
                      <Route path="/AcademicReport" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AcademicReport /> : <Navigate to="/" />} />
                      <Route path="/AddAcademicYear" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddAcademicYear /> : <Navigate to="/" />} />
                      <Route path="/AddAcademicTerm" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddAcademicTerm /> : <Navigate to="/" />} />
                      <Route path="/AddClass" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddClass /> : <Navigate to="/" />} />
                      <Route path="/AllClasses" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AllClasses /> : <Navigate to="/" />} />
                      <Route path="/AddSubject" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='SUPREMO' ? <AddSubject /> : <Navigate to="/" />} />

                    {/* teachers routes and pages  */}
                      <Route path="/TPAttendance" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPAttendance /> : <Navigate to="/" />} />
                      <Route path="/TPGenerateReport" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPGenerateReport /> : <Navigate to="/" />} />
                      <Route path="/TPGrades" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPGrades /> : <Navigate to="/" />} />
                      <Route path="/TPMessages" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPMessages /> : <Navigate to="/" />} />
                      <Route path="/TPStudents" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPStudents /> : <Navigate to="/" />} />
                      <Route path="/TPStudentDetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='CLASS-TEACHER' ? <TPStudentDetails /> : <Navigate to="/" />} />


                      {/* bursars routes and pages  */}
                      <Route path="/BPMessages" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPMessages /> : <Navigate to="/" />} />
                      <Route path="/BPFinance" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPFinance /> : <Navigate to="/" />} />
                      <Route path="/BPInventory" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPInventory /> : <Navigate to="/" />} />
                      <Route path="/BPStudents" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPStudents /> : <Navigate to="/" />} />
                      <Route path="/BPAddStudent" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAddStudent /> : <Navigate to="/" />} />
                      <Route path="/BPStudentDetails" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPStudentDetails /> : <Navigate to="/" />} />
                      <Route path="/BPAttendance" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAttendance /> : <Navigate to="/" />} />
                      <Route path="/BPAddExpenses" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAddExpenses /> : <Navigate to="/" />} />
                      <Route path="/BPAddIncome" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAddIncome /> : <Navigate to="/" />} />
                      <Route path="/BPAllExpenses" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAllExpenses /> : <Navigate to="/" />} />
                      <Route path="/BPAllIncome" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='ADMINISTRATION' ? <BPAllIncome /> : <Navigate to="/" />} />
                     

                     {/* students routes and pages */}
                     <Route path="/SPMessages" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='STUDENT' ? <SPMessages /> : <Navigate to="/" />} />
                      <Route path="/SPGrades" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='STUDENT' ? <SPGrades /> : <Navigate to="/" />} />
                      <Route path="/SPFinance" element={ localStorage.getItem('loginStatus')==='true' && localStorage.getItem('userRole')==='STUDENT' ? <SPFinance /> : <Navigate to="/" />} />
                     
                      <Route path="/Logout" element={ localStorage.getItem('loginStatus')==='true' ? <Logout /> : <Navigate to="/" />} />
                      <Route path="*" element={ localStorage.getItem('loginStatus')==='true'?<PageNotFound />:<Navigate to="/" /> } />
              </Routes>
        </Router>
          </UserInterfaceProvider>
          </StageContextProvider>
      </UserDataContextProviders>


  );
}



export default App;
