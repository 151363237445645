import React, { useContext, useDebugValue, useEffect, useState } from 'react'
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { FinancialActions } from './TPCOMPONENTS/bp-reusables'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context'
import { Loading } from '../Components/reusables'


function BPAllExpenses() {
    const {Progress, setLoading } = useContext (UserInterfaceContext)

    const [academicYear, setAcademicYear] = useState([])
    const [academicTerm, setAcademicTerm] = useState([])
    const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
    const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [expenseType, setExpenseType] = useState()
    const [expenseData, setExpenseData] = useState([])



    const fetchAcademicYear = async () => {
        try {
          Progress('30%');
          const academicYearResponse = await axios.get('https://new-softesc-backend.onrender.com/academicYear', {
           
          });
          //console.log(academicYearResponse);
          setAcademicYear(academicYearResponse.data);
          Progress('100%');
        } catch (error) {
          console.error(error);
          Progress('0%');
        }
      };
    
      const fetchAcademicTerm = async () => {
        try {
          //console.log('selected academic year', selectedAcademicYear)
          Progress('70%');
          const academicTermResponse = await axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
            params:{
                academicYearName : selectedAcademicYear===''?'none':selectedAcademicYear,
            }
          });
          //console.log(academicTermResponse);
          setAcademicTerm(academicTermResponse.data);
          Progress('100%');
        } catch (error) {
          console.error(error);
          Progress('0%');
        }
      };
    
      const fetchData = async ()  =>{
        Progress('30%')
        setLoading(true)
        axios.get('https://new-softesc-backend.onrender.com/finance/expense', {
          params:{
            academicYear : selectedAcademicYear,
            academicTerm : selectedAcademicTerm,
            expenseType : expenseType,
            startDate : startDate,
            endDate: endDate,
          }
        })
        .then((response)=>{
          setLoading(false )
          Progress('100%')
          setExpenseData(response.data)
          //console.log('expenses retrieved successfully')
      
        })
        .catch((error)=> {
          Progress('0%')
          //console.log(error)
        })
      }
    
      useEffect(()=>{
        fetchData()
        fetchAcademicTerm();
        fetchAcademicYear()
      
      },[selectedAcademicTerm,selectedAcademicYear, expenseType, startDate, endDate])
      
    
    
      const [sortingView, setSortingView] = useState(false)
      function toggleSortingView (){
        setSortingView(!sortingView)
      }



      

  return (
    <div className='App'>
      <BPSideMenu/>
      <div className='main-menu'>
        <BPHeader/>
        <div  className='content-loader'>
        <FinancialActions/>

            {/* the main menu content goes here  */}
<div className="sorting sticky-left">
        <div className="sorting-header" onClick={toggleSortingView}>
           <h2>Sort By:</h2>
          <IoIosArrowDown id='sorting-down-arrow'/>
           </div>                
                <div className="sorting-body" style={{display: sortingView?'flex':'none'}} >
                <div className="input">
                <label htmlFor="">Academic Year: </label>
                <select 
                  name="" 
                  id=""
                  value={selectedAcademicYear}
                  onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}

                  >
                  <option value="" disabled hidden >select an academic year</option>
                  <option value="" >All</option>
                  {academicYear.map((year, index)=>(
                  <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

            ))}            

          </select>
          

        </div>
        <div className="input">
          <label htmlFor="">Academic Term: </label>
          <select 
            name="" 
            id=""
            value={selectedAcademicTerm}
            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
            >
            <option value="" disabled hidden>select an academic term</option>
            {academicTerm.map((term, index)=>(
                  <option key={index} value={term.academicTermName}>{term.academicTermName}</option>

            ))}
          </select>
          
          
        </div>
                <div className="input">
                <label htmlFor="">From: </label>
                <input 
                  type="date" 
                  value={startDate}
                  onChange={(e)=>{setStartDate(e.target.value)}}
                />
                </div>

              <div className="input">
              <label htmlFor="">To: </label>
                <input 
                  type="date"
                  value={endDate}
                  onChange={(e)=>{setEndDate(e.target.value)}}

                  
                  />
              </div>
            <div className="input">
            <label htmlFor="">Expense Type: </label>
            <select 
              name="" 
              id=""
              value={expenseType}
              onChange={(e)=>{setExpenseType(e.target.value)}}
              >
                <option value="">All </option>
                <option value="salary">Salary </option>
                <option value="snnit">SNNIT TIER 2 </option>
                <option value="gre">GRE - TAX </option>
                <option value="waterbill">Water Bill</option>
                <option value="electricity-bill">Electricity Bill Fees</option>
                <option value="bookstationary">Books and Stationary</option>
                <option value="maintenance">Maintaince</option>
                <option value="transportation">Transportation</option>
                <option value="canteen">Canteen</option>
                <option value="others">Others</option>
            </select>
            </div>
                </div>
        </div>
       
        <div className="container">
          <table className='user-table'>
            <Loading/>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Expense Type</th>
                  <th id='user-name-head'>Paid To </th>
                  <th>Amount (GHS)</th>
                  <th>Phone / Email</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th id='user-actions-head'>Action</th>
                  {/* Add more header cells as needed */}
                </tr>
              </thead>
              <tbody>
                {
                  expenseData.map((expense, index)=>(
                    <tr key={index}>
                      <td>{expense._id}</td>
                      <td>{expense.expenseType}</td>
                      <td id='user-name'>{expense.name}</td>
                      <td>{expense.amount}</td>
                      <td>{expense.telephone}</td>
                      <td>{expense.description}</td>
                      <td>{expense.dateAdded}</td>
                      {/* <td>{expense}</td> */}
                      <td id='table-actions'>
                        {/* <FaEye id='action-see'/>  */}
                        {/* <FaEdit/>  */}
                        {/* <FaTrash id='action-delete'/> */}.
                        </td>
                    </tr>
                  ))
                }
                
                
               
                
              
                
                
                
              </tbody>
        </table>
      </div>        </div>

      </div>
    </div>
  )
}

export default BPAllExpenses