import React from 'react'
import axios from 'axios'
import TPHeader from './TPCOMPONENTS/tp-header'
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { useContext,useState, useEffect } from 'react'

function TPMessages() {
  const {setPageName, Progress, AlertNotice} = useContext(UserInterfaceContext)
  setPageName('NOTICES AND MESSAGES')
  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }

  const [pastMessages, setPastMessages] = useState([])
  const [reciever, setReciever] = useState('')
  const [sender, setSender] = useState('Admin')
  const [title, setTitle] = useState('')
  const [channel, setChannel] = useState('')
  const [messageBody, setMessageBody] = useState()
  const [pastMessageReload, setPastMessageReload] = useState(false)

  // const messageData = {
  //   reciever : reciever,
  //   sender : sender,
  //   title : title,
  //   channel: channel,
  //   messageBody : messageBody,
  // }

  // function ResetMessageInputs (){
  //   setReciever('')
  //   setTitle('')
  //   setMessageBody('')

  // }


  // function handleMessageSubmit (){
  //   Progress('30%')
  //   //console.log('processing new notice submission')
  //   // axios.post('https://new-softesc-backend.onrender.com/notices', messageData)
  //   axios.post('https://new-softesc-backend.onrender.com/notices', messageData)
  //   .then((response)=>{
  //     AlertNotice('Notice sent succesfully', true)
  //     Progress("100%")
  //     setPastMessageReload(!pastMessageReload)
  //     // ////console.log(response.data.message)
  //     // ////console.log(response.data)

  //   })
  //   .catch((error)=>{
  //     AlertNotice('Unable to send Notice', false)
  //     //console.log('something went wrong', error)
  //     Progress('0%')
  //   })
  // }


  //call for the past messages

  useEffect(()=>{
    Progress('30%')
     // axios.get('https://new-softesc-backend.onrender.com/notices'
    axios.get('https://new-softesc-backend.onrender.com/notices', {
      params :{
        reciever: 'Staff'
      }
    })
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
      ////console.log(response)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })

  },[pastMessageReload])


  return (
    <div className='App'>
      <TPSideMenu/>
      <div className='main-menu'>
        <TPHeader/>
        <div  className='content-loader'>
            {/* the main menu content goes here  */}
            <div className="past-messages">
              <h2 className='class-level past-message-header'>MESSAGES </h2>
                <div className="past-messages-body">
                {pastMessages.map((pastMessages, index)=>(
                    <div className="message" key={index}>
                    <h3 id='message-title' style={{color: 'green'}}>Reciever : {pastMessages.reciever}  </h3>
                    <h3 id='message-title'>Message title : {pastMessages.title} </h3>
                    <h3 id='message-title' style={{color: 'red'}}>Date Sent : {pastMessages.date} </h3>
                    <p id='message-body'>Message :{pastMessages.messageBody}</p>
                    
                  </div>
                ))}
                </div>
                
                                
              </div>
        </div>

      </div>
    </div>
  )
}

export default TPMessages