import axios from 'axios';
import React, { useContext, useState,useEffect } from 'react'
import './classes.css'
import { NavLink } from 'react-router-dom'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { StageContext } from '../../Contexts/stage-context';
import { UserDataContext } from '../../Contexts/user-data';
import { Loading } from '../../Components/reusables';
import Receipt from '../finance/receipt';

function AllClasses() {
  const {setPageName, setLoading} = useContext(UserInterfaceContext)
  const [stageData, setStageData] = useState([])
  const {setUserId} = useContext(UserDataContext)
  const {Progress, setShowDeletePallet} = useContext(UserInterfaceContext)
  const {selectedStage, setSelectedStage } = useContext(StageContext)
  const [studentData, setStudentData] = useState([])

  setPageName('CLASSROOM')
  async function FetchClassData (){
    if(selectedStage===''){
      return
    }
    else {
      setLoading(true)
      axios.get('https://new-softesc-backend.onrender.com/stages/stageTerm',
      {
        params : {
        stage : selectedStage
      }}
      )
      .then((response)=>{
        setLoading(false)
          ////console.log(response.data)
          setStageData(response.data)
      })
      .catch((error)=>{
          //console.log(error)
      })
    }
  }
  
  async function FetchClassStudentData (){
    //console.log(selectedStage)
   if(selectedStage===''){
    return
   }
   else{
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/students',
    {
      params : {
      stage : selectedStage
    }}
    )
    .then((response)=>{
      Progress('100%')
        ////console.log(response.data)
        setStudentData(response.data)
    })
    .catch((error)=>{
        //console.log(error)
    })
   }
  }
  
    useEffect(()=>{
      FetchClassData()
      FetchClassStudentData()
  },[selectedStage])
  
  
  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('Basic 1')
    }
    else if(stage===2){
        return('Basic 2')
    }
    else if(stage===3){
        return('Basic 3')
    }
    else if(stage===4){
        return('Basic 4')
    }
    else if(stage===5){
        return('Basic 5')
    }
    else if(stage===6){
        return('Basic 6')
    }
    else if(stage===7){
        return('Basic 7 / JHS 1')
    }
    else if(stage===8){
        return('Basic 8 / JHS 2')
    }
    else if(stage===9){
        return('Basic 9 / JHS 3')
    }
  }


  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <div className='all-classes content-loader'>
          <div className="action-buttons">
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===-4?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(-4)}}>{'Creche'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===-3?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(-3)}}>{'Nursery 1'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===-2?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(-2)}}>{'Nursery 2'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===-1?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(-1)}}>{'Kindergarten 1'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===0?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(0)}}>{'Kindergarten 2'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===1?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(1)}}>{'Basic 1'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===2?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(2)}}>{'Basic 2'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===3?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(3)}}>{'Basic 3'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===4?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(4)}}>{'Basic 4'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===5?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(5)}}>{'Basic 5'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===6?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(6)}}>{'Basic 6'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===7?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(7)}}>{'Basic 7'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===8?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(8)}}>{'Basic 8'} </button></NavLink>
            <NavLink id='Link' className='Link' ><button id='actionbutton' style={{backgroundColor: selectedStage===9?'#f47c2c':'#031c3b', color :'white'}} onClick={()=>{setSelectedStage(9)}}>{'Basic 9'} </button></NavLink>
          </div>
       

        {/* <div className="details-header "> */}
        {stageData.map((stageData, index)=>(
        
            <div className="details personal-info" key={index}>
              <Loading/>
              <h3> <b style={{color: 'white'}}>{GenerateStageName(stageData.stage)}</b> </h3> 
              <label htmlFor="">Class  Number ::::::::::::::: {(stageData.stage)} </label> 
              <label htmlFor="">Name of Teacher :::::::::: {stageData.stageTeacher ? stageData.stageTeacher.name : <b style={{color:'red'}}> NO CLASS TEACHER</b> }</label> 
              <label htmlFor="">Number of Students :::: {stageData.numOfBoys + stageData.numOfGirls} </label> 
              <label htmlFor="">Number of Boys ::::::::::: {stageData.numOfBoys} </label> 
              <label htmlFor="">Number of Girls :::::::::::: {stageData.numOfGirls} </label> 
              <label htmlFor="">School fees :::::::::::::::::::: {stageData.schoolFees} </label> 
              <label htmlFor="">Total school fees ::::::::::: {stageData.totalFees} </label> 
              <label htmlFor="">Paid school fees :::::::::::: {stageData.totalPaid}</label> 

          </div>
        )) }
        
      
        {/* </div> */}
        {/* <br /> */}
        <div className="container">
        <table className='user-table'>
          <Loading/>
              <thead>
                <tr>
                  <th>Student ID: </th>
                  <th>Photo</th>
                  <th id='user-name-head'>Name</th>
                  <th>Class </th>
                  <th>Gender</th>
                  <th>House</th>
                  {/* <th>Class Teacher</th> */}
                  {/* <th>
                    School Fees
                    <div id='inner-th'>
                      <em id='paid'>Paid (GHS)</em>  
                      <em id='balance'>Balance(GHS)</em>  
                    </div></th> */}
                  <th>Address</th>
                  <th>Date of Birth</th>
                  <th>Parent / Guardian</th>
                  <th>Telephone</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th id='user-actions-head'>Action</th>
                  {/* Add more header cells as needed */}
                </tr>
              </thead>
              <tbody>
                {studentData.length>0?(studentData.map((studentData, index)=>(
                    <tr key={index}>
                    <td id='no-wrap'>{studentData.refNumber}</td>
                    <td id='no-wrap'>N/A</td>
                    <td id='user-name'>{studentData.firstName + " " + studentData.lastName}</td>
                    <td id='no-wrap'>{studentData.stage}</td>
                    <td id='no-wrap'>{studentData.gender}</td>
                    <td id='no-wrap'>{studentData.house}</td>
                    {/* <td id='no-wrap'>{studentData.classTeacher}</td> */}
                    {/* <td id='inner-td'><em id='paid'>{studentData.paidFeesAmount}</em> <em id='balance'>{studentData.feesBalance}</em></td> */}
                    <td id='no-wrap'>{studentData.presentAddress}</td>
                    <td id='no-wrap'>{studentData.dob}</td>
                    <td id='no-wrap'>F:{studentData.fatherName }<br /> M:{studentData.motherName}</td>
                    <td id='no-wrap'>F:{studentData.fatherPhone }<br /> M:{studentData.motherPhone}</td>
                    <td id='no-wrap'>{studentData.email}</td>
                    <td id='no-wrap'>{studentData.userStatus}</td>
                    {/* <td id='table-actions'><NavLink to='/StudentDetails'><FaEye id='action-see' onClick={()=>{setUserId(studentData.refNumber)}}/></NavLink> <FaEdit/> <FaTrash id='action-delete'/></td> */}
                    <td id='table-actions'>
                      <NavLink to="/StudentDetails" onClick={async() => await setUserId(studentData.refNumber)}>
                          <FaEye id="action-see" />
                        </NavLink>
                      <NavLink to='/StudentUpdate' onClick={async()=> await setUserId(studentData.refNumber)}>
                          <FaEdit id='action-update'/> 
                      </NavLink>
                      <NavLink>
                        <FaTrash id='action-delete' onClick={()=>{
                          setShowDeletePallet(true); 
                          setUserId(studentData.refNumber)
                          }}/>
                      </NavLink>
                    </td>
                  </tr>
                  ))):
                  (<tr >
                    <td colSpan ='14'style={{padding:'1rem', color:'red'}}>No Students in the Class</td>
                  </tr>)
                }
        
              </tbody>
        </table>

        </div>
           
    </div>
        </div>

      </div>
    
  )
}

export default AllClasses