import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'
import { UserDataContext } from '../Contexts/user-data'
import axios from 'axios'
import loadergif from '../images/icons/Spinner@1x-1.0s-200px-200px (4).gif'
// import { ProgressBar } from './reusables';
// import { UserInterfaceContext } from '../Contexts/ui-context'

export function Alert(){
  const {alertMessage, alertStatus, displayAlert,setDisplayAlert} =useContext(UserInterfaceContext)
  return(
    <div className='alert-notice' style={{maxWidth: displayAlert==true?'20rem':'0rem',width: displayAlert==true?'20rem':'0rem', backgroundColor: alertStatus?'green':'rgb(240, 82, 82)' }}>
      {alertStatus==true?<FaCheckCircle id='noticeIcon'/>:alertStatus==false?<FaTimes id='noticeIcon'/>:null}
      <p>{alertMessage}</p>
    </div>
  )
}

export function ProgressBar(){
  const {progressPercentage} = useContext(UserInterfaceContext)
  return(
<div className='progressBar' >
      <div className="progressPercentage" style={{width: progressPercentage,}}>
      </div>
    </div>
  )
}

export function Loading(){
  const {progressPercentage, loading} = useContext(UserInterfaceContext)
  
  return(
    <>
    {
        loading===true?(
          <div className='loading' >
      <img src={loadergif} alt="Loading ..." />
    </div>
        ):null
      }
    </>
      
  )
}

export function SuperActionButtons (){
  const {setPageName} = useContext(UserInterfaceContext)

  

  return(
      <div className='super-head'>
              {/* <h2>Owner Management Board</h2> */}
              <div className='   action-buttons'>
                <NavLink id='Link' className='Link' to='/MainDashBoard' ><button id='actionbutton'>{'Summary'}</button></NavLink>
                <NavLink id='Link' className='Link' to='/AddAcademicYear' ><button id='actionbutton' onClick={setPageName('DASHBOARD')}>{'Academic Year'}</button></NavLink>
                <NavLink id='Link' className='Link' to='/AddAcademicTerm'><button id='actionbutton' onClick={setPageName('DASHBOARD')}>{'Academic Term'}</button></NavLink>
                <NavLink id='Link' className='Link' to='/AddClass' ><button id='actionbutton' onClick={setPageName('DASHBOARD')} >{'Classes'} </button></NavLink>
                <NavLink id='Link' className='Link' to='/AddSubject'><button id='actionbutton' onClick={setPageName('DASHBOARD')}>{'Courses'} </button></NavLink>
                {/* <NavLink id='Link' className='Link' to='/AddExpense'><button id='actionbutton'>Add Expenses </button></NavLink> */}

              </div>
          </div>
  )
}


export function DeletePrompt (){
  const {userId} = useContext(UserDataContext)
  const {showDeletePallet, ToggleShowDelete } = useContext(UserInterfaceContext)
  const [confirmInput, setConfirmInput] = useState('')
  // const [canDelete, setCanDelete] = useState(false)
  const {Progress, AlertNotice} = useContext(UserInterfaceContext)

  useEffect(()=>{
    //console.log(userId, confirmInput)
  },[confirmInput])



async function HandleDelete(){
  if (userId === confirmInput){
    // setCanDelete(true)
    Progress('30%');
  try {
    await axios.delete(userId.startsWith('STF')?`https://new-softesc-backend.onrender.com/staff/${userId}`:`https://new-softesc-backend.onrender.com/students/${userId}`);
    AlertNotice(('User Deleted Successfully'), true);
    Progress('100%')
  } catch (error) {
    // console.error('Student Deletion failed', error);
    AlertNotice('Unable to delete Student', false);
    Progress('0%')
  } finally {
    setConfirmInput('');
  }
  setConfirmInput('');
  }
  else{
    AlertNotice("User ID's dont match, Try Again", false);

  }
  
};



  return(
    <div className='deletePrompt' style={{display: showDeletePallet?'flex':'none'}}>
      <div className="deletePallet">
      <ProgressBar/>
      <b id='closeButton' onClick={ToggleShowDelete}>X</b>
        <p>Delete user with ID {userId} ? </p>
        <input 
          type="text" 
          placeholder='Type Id to confirm action'
          value={confirmInput}
          onChange={(e)=>{setConfirmInput(e.target.value)}}

          />
        <div id="submit-reset-buttons" >
          <button onClick={HandleDelete} style={{marginTop: '1rem'}}>Yes, Delete</button>
          {/* <button >No, Cancel</button> */}
        </div>
      </div>
    </div>
  )
}



function generateRandomKey() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let actionKey = '';
  for (let i = 0; i < 7; i++) {
      actionKey += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return actionKey;
}


export function DeleteFinanceEntryPrompt (){
  const [userInput, setUserInput] = useState('')
  const {showFinancialDeletePallet, ToggleShowFinancialDelete, transaction_id, typeofEntry } = useContext(UserInterfaceContext)
  const [actionKey, setActionKey] = useState(generateRandomKey())
  const {Progress, AlertNotice} = useContext(UserInterfaceContext)

  useEffect(()=>{
    //console.log(userInput, actionKey)
  },[actionKey])
    //console.log(actionKey)
    //console.log(userInput)
    //console.log(transaction_id)



function HandleDelete(){
  //console.log('processing finance delete ')
  //console.log(transaction_id)

  axios.delete(`https://new-softesc-backend.onrender.com/finance/${transaction_id}`,{
      params: {
        typeofEntry : typeofEntry,
        transaction_id
      }
    })
    .then((response)=>{
      AlertNotice(('Entry deleted Successfully'), true);
      Progress('100%')
    })
    .catch((error)=>{
      //console.log(error)
      AlertNotice('Unable to delete Entry', false);
    Progress('0%')
    })
}




//console.log(transaction_id,typeofEntry)

  return(
    <div className='deletePrompt' style={{display: showFinancialDeletePallet?'flex':'none'}}>
      <div className="deletePallet">
      <ProgressBar/>
      <b id='closeButton' onClick={()=>{ToggleShowFinancialDelete(); window.location.reload()}}>X</b>
        <p>Delete Finance Entry? Key : {actionKey}  </p>
        <input 
          type="text" 
          placeholder='Re-type the Action Key Above'
          value={userInput}
          onChange={(e)=>{setUserInput(e.target.value)}}

          />
        <div id="submit-reset-buttons" >
          <button onClick={HandleDelete} style={{marginTop: '1rem'}}>Yes, Delete</button>
          {/* <button >No, Cancel</button> */}
        </div>
      </div>
    </div>
  )
}



export function FinancialActions (){
  const {setPageName} = useContext(UserInterfaceContext)
  

    return(
            <div className='   action-buttons'>
              <NavLink id='Link' className='Link' to='/FeeCollection' ><button id='actionbutton' onClick={setPageName('FINANCE & ACCOUNTS')}>Fees Collection</button></NavLink>
              <NavLink id='Link' className='Link' to='/AllIncome'><button id='actionbutton' onClick={setPageName('FINANCE & ACCOUNTS')}>All Income</button></NavLink>
              <NavLink id='Link' className='Link' to='/AddIncome' ><button id='actionbutton' onClick={setPageName('FINANCE & ACCOUNTS')}>Add Income </button></NavLink>
              <NavLink id='Link' className='Link' to='/AllExpenses'><button id='actionbutton'onClick={setPageName('FINANCE & ACCOUNTS')}>All Expenses</button></NavLink>
              <NavLink id='Link' className='Link' to='/AddExpense'><button id='actionbutton'onClick={setPageName('FINANCE & ACCOUNTS')}>Add Expenses </button></NavLink>

            </div>
    )
}
export function StudentActions () {
  const {setPageName, smallScreenView} = useContext(UserInterfaceContext)

  
  return (
          <div className="  action-buttons">
                <NavLink id='Link' to='/AllStudent'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>All Student </button></NavLink>
                <NavLink id='Link' to='/Addstudent'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Enroll Student</button></NavLink>
                <NavLink id='Link' to='/Attendance'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Attendance</button></NavLink>
                <NavLink id='Link' to='/Grades'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Grades</button></NavLink>
                <NavLink id='Link' to='/AcademicReport' ><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Report</button></NavLink>
                <NavLink id='Link' to='/AddIncome' ><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Add School Fees</button></NavLink>
                {/* <NavLink id='Link' to='/AllNotices'><button id='actionbutton' onClick={setPageName('NOTICE & MESSAGES ')}>Send Message</button></NavLink> */}
          </div>

  )
}

export function TeachersActions (){
  const {setPageName} = useContext(UserInterfaceContext)
  

  return(
      <div className="  action-buttons">
        <NavLink id='Link' to='/AllTeachers'> <button id='actionbutton' onClick={setPageName('STAFF')}>Staff Data</button></NavLink>
        <NavLink id='Link' to='/Addteacher'> <button id='actionbutton' onClick={setPageName('STAFF')}>Add Staff</button></NavLink>
        {/* <NavLink id='Link' to='/Addteacher'> <button id='actionbutton' onClick={setPageName('STAFF')}>Take Attendance</button></NavLink> */}
        {/* <NavLink id='Link' to='/AllNotices'><button id='actionbutton' onClick={setPageName('NOTICE & MESSAGES')}>Send Message</button></NavLink> */}
        
        </div>
  )
}


export function InventoryActions (){
  const {setPageName} = useContext(UserInterfaceContext)

  return(
    <div className='action-buttons'>
        <NavLink id='Link' to='/Inventory'> <button id='actionbutton' onClick={setPageName('INVENTORY')}>All Inventory / Stock </button></NavLink>
        <NavLink id='Link' to='/Uniforms'> <button id='actionbutton' onClick={setPageName('INVENTORY')}>Uniforms</button></NavLink>
        <NavLink id='Link' to='/Books'> <button id='actionbutton' onClick={setPageName('INVENTORY')}>Books</button></NavLink>
        <NavLink id='Link' to='/Computers'> <button id='actionbutton' onClick={setPageName('INVENTORY')}>Computers</button></NavLink>
        <NavLink id='Link' to='/Furniture'> <button id='actionbutton' onClick={setPageName('INVENTORY')}>Furnitures</button></NavLink>

    </div>
  )
}
export function LibraryActions (){
  const {setPageName} = useContext(UserInterfaceContext)

  return(
    <div className='action-buttons'>
        <NavLink id='Link' to='/AllBooks'> <button id='actionbutton' onClick={setPageName('LIBRARY')}>All Books </button></NavLink>
        <NavLink id='Link' to='/AllBooks'> <button id='actionbutton' onClick={setPageName('LIBRARY')}>Add Books</button></NavLink>
        <NavLink id='Link' to='/AllBooks'> <button id='actionbutton' onClick={setPageName('LIBRARY')}>Lend Book</button></NavLink>
    </div>
  )
}


function reusables() {
  return (
    <>
      <ProgressBar/>
      <SuperActionButtons/>
     <FinancialActions/>
     <StudentActions/>
     <TeachersActions/>
     <Alert/>
     <DeletePrompt/>
     <InventoryActions/>
     <LibraryActions/>
     <Loading/>
     <DeleteFinanceEntryPrompt/>
   </>
  )
}

export default reusables