import React from "react"
import { useEffect, useState,useContext } from "react"
import { UserInterfaceContext } from "../../Contexts/ui-context"
import { FaCheckCircle, FaTimes } from "react-icons/fa"
import { NavLink } from 'react-router-dom'

export function Alert(){
    const {alertMessage, alertStatus, displayAlert,setDisplayAlert} =useContext(UserInterfaceContext)
    return(
      <div className='alert-notice' style={{maxWidth: displayAlert==true?'20rem':'0rem',width: displayAlert==true?'20rem':'0rem', backgroundColor: alertStatus?'green':'rgb(240, 82, 82)' }}>
        {alertStatus==true?<FaCheckCircle id='noticeIcon'/>:alertStatus==false?<FaTimes id='noticeIcon'/>:null}
        <p>{alertMessage}</p>
      </div>
    )
  }

  

  export function ProgressBar(){
    const {progressPercentage} = useContext(UserInterfaceContext)
    return(
  <div className='progressBar' >
        <div className="progressPercentage" style={{width: progressPercentage,}}>
        </div>
      </div>
    )
  }

  export function StudentActions () {
    const {setPageName} = useContext(UserInterfaceContext)
  
    
    
    return (
            <div className="  action-buttons">
                  <NavLink id='Link' to='/TPStudents'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Student Data</button></NavLink>
                  {/* <NavLink id='Link' to='/Addstudent'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Enroll Student</button></NavLink> */}
                  <NavLink id='Link' to='/TPAttendance'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Take Attendance</button></NavLink>
                  <NavLink id='Link' to='/TPGrades'><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Add Grades</button></NavLink>
                  <NavLink id='Link' to='/TPGenerateReport' ><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Generate Academic Report</button></NavLink>
                  {/* <NavLink id='Link' to='/AddIncome' ><button id='actionbutton' onClick={setPageName('STUDENTS ')}>Add Student Payment</button></NavLink> */}
                  {/* <NavLink id='Link' to='/AllNotices'><button id='actionbutton' onClick={setPageName('NOTICE & MESSAGES ')}>Send Message</button></NavLink> */}
            </div>
  
    )
  }
  

  function reusables() {
  return (
    <>
      <ProgressBar/>
     <Alert/>
   </>
  )
}