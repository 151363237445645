import React, { useContext,useEffect,useState } from 'react'
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context';

function BPMessages() {

  const {setPageName, Progress, AlertNotice , setLoading} = useContext(UserInterfaceContext)

  setPageName('NOTICES AND MESSAGES')

  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }
  


  const [pastMessages, setPastMessages] = useState([])
  const [reciever, setReciever] = useState('')
  const [sender, setSender] = useState('Admin')
  const [title, setTitle] = useState('')
  const [channel, setChannel] = useState('')
  const [messageBody, setMessageBody] = useState()
  const [date, setDate]= useState(new Date())
  const [pastMessageReload, setPastMessageReload] = useState(false)
  const [stage, setStage] = useState('')
  const [studentData, setStudentData] = useState([]);

  const messageData = {
    reciever : reciever,
    sender : sender,
    title : title,
    channel: channel,
    messageBody : messageBody,
    date : date,
  }

  function ResetMessageInputs (){
    setReciever('')
    setTitle('')
    setMessageBody('')

  }


  function handleMessageSubmit (){
    Progress('30%')
    //console.log('processing new notice submission')
    // axios.post('https://new-softesc-backend.onrender.com/notices', messageData)
    axios.post('https://new-softesc-backend.onrender.com/notices', messageData)
    .then((response)=>{
      AlertNotice('Notice sent succesfully', true)
      Progress("100%")
      setPastMessageReload(!pastMessageReload)
      // ////console.log(response.data.message)
      // ////console.log(response.data)

    })
    .catch((error)=>{
      AlertNotice('Unable to send Notice', false)
      //console.log('something went wrong', error)
      Progress('0%')
    })
  }


  //call for the past messages

  useEffect(()=>{
    Progress('30%')
     // axios.get('https://new-softesc-backend.onrender.com/notices'
    axios.get('https://new-softesc-backend.onrender.com/notices')
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
      ////console.log(response)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })

  },[pastMessageReload])



  //call for students data
  useEffect(() => {
    if(stage===''){
      return
    }
    setLoading(true)
    Progress('30%');
    axios
      .get('https://new-softesc-backend.onrender.com/students', {
        params: {
          stage: stage,
        },
      })
      .then((response) => {
        Progress('100%');
        ////console.log(response.data);
        setStudentData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        Progress('0%');
        //console.log(error);
      });
  // }
}, [stage]);


  return (
    <div className='App'>
      <BPSideMenu/>
      <div className='main-menu'>
        <BPHeader/>
        <div className='notices content-loader'>
            <div className="sorting">
              <div className="sorting-header" onClick={toggleSortingView}>
              <h2>Send a Note / Message</h2>
              <IoIosArrowDown id='sorting-down-arrow'/>

              </div>
              <div className="sorting-body"style={{display: sortingView?'none':'flex'}}>
              <div className='input'>
              <label htmlFor="">Receiver: </label>
              <select 
              name="" 
              id=""
              value={reciever}
              onChange={(e)=>{setReciever(e.target.value)}}
              >
                <option value="">Select Reciever Group</option>
                <option value="Parents">Parents</option>
                <option value="Staff">General Staff</option>
                {/* <option value="TeachingStaff">Teaching Staff</option> */}
                {/* <option value="NonTeachingStaff">Non-teaching Staff</option> */}
              </select>
              </div>


              <div className="input">
            <label htmlFor="">Class: </label>
            <select 
              value={stage}
              onChange={(e)=>{setStage(e.target.value)}}
            >
                <option value="">All </option>
                <option value={-4}>Preschool - Creche</option>
                <option value={-3}>Preschool - Nursery 1</option>
                <option value={-2}>Preschool - Nursery 2</option>
                <option value={-1}>Preschool - Kingdergarten 1</option>
                <option value={0}>Preschool - Kingdergarten 2</option>
                <option value={1}>Class 1</option>
                <option value={2}>Class 2</option>
                <option value={3}>Class 3</option>
                <option value={4}>Class 4</option>
                <option value={5}>Class 5</option>
                <option value={6}>Class 6</option>
                <option value={7}>Class 7 / JHS 1</option>
                <option value={8}>Class 8 / JHS 2</option>
                <option value={9}>Class 9 / JHS 3</option>
            </select>


          </div>


              <div className='input'>
              <label htmlFor="">Channel: </label>
              <select name="" id=""
                value={channel}
                onChange={(e)=>{setChannel(e.target.value)}}
              >
                <option value="">Select Channel</option>
                <option value="sms">SMS</option>
                <option value="email">EMAIL</option>
                {/* <option value="sms-email">SMS AND EMAIL</option> */}
                {/* <option value="dashboard">USER - DASHBOARD</option> */}
                {/* <option value="all">ALL CHANNELS</option> */}
              </select>

              </div>
              <div className='input'>
              <label htmlFor="">Title of Message: </label>
              <input 
                type="text"
                placeholder='Title of the text you want to send' 
                value={title}
                onChange={(e)=>{setTitle(e.target.value)}}
              /> 

              </div>
              <div className='notice-textarea'>
              <div className="input ">
              <label htmlFor="">Message: </label>
              <textarea 
              name="" 
              id="" 
              cols="30" 
              rows="10" 
              placeholder='Enter your message here'
              value={messageBody}
              onChange={(e) => {setMessageBody(e.target.value)}}
              ></textarea> 
              </div>
              <br />
              <div id='submit-reset-buttons' >
                <button onClick={handleMessageSubmit}>Send Message</button>
                <button onClick={ResetMessageInputs}>reset inputs</button>
              </div>
              </div>
              </div>
              
              
            </div>
            <div className="past-messages">
              <h2 className='class-level past-message-header'>PAST MESSAGES </h2>
                <div className="past-messages-body">
                {pastMessages.map((pastMessages, index)=>(
                    <div className="message" key={index}>
                    <h3 id='message-title' style={{color: 'green'}}>Reciever : {pastMessages.reciever}  </h3>
                    <h3 id='message-title'>Message title : {pastMessages.title} </h3>
                    <h3 id='message-title' style={{color: 'red'}}>Date Sent : {pastMessages.date} </h3>
                    <p id='message-body'>{pastMessages.messageBody}</p>
                    
                  </div>
                ))}
                </div>
                
                                
              </div>
            </div>

      </div>
    </div>
  )
}

export default BPMessages