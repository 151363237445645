import React, { useContext, useState, useEffect } from 'react'
import { SuperActionButtons, Alert } from '../../Components/reusables'
import { SideMenu } from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import axios from 'axios'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { Loading } from '../../Components/reusables'


function AddSubject() {
    const {Progress, AlertNotice,  isSubmitting, setIsSubmitting, loading, setLoading} = useContext(UserInterfaceContext)
    const [stage, setStage] = useState('');
    const [subject, setSubject] = useState('')    
    // const [teachers, setTeachers] = useState([]);
    // const [typeOfTeacher, setTypeOfTeacher] = useState('')
    const [allSubjects, setAllSubjects] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [sortingView, setSortingView] = useState(true)



    function toggleSortingView (){
        setSortingView(!sortingView)
      }

    // a call to the teachers 
    useEffect(() => {
        FetchAllSubjects()
        // FetchAllTeachers()
        setIsSubmitting(false)
      }, [refresh, stage, subject]);


      async function FetchAllSubjects (){
        setLoading(true)
        Progress('30%')
       await axios.get('https://new-softesc-backend.onrender.com/subject')
        .then((response)=>{
            Progress('100%')
            setAllSubjects(response.data)
            setLoading(false)
            // ////console.log(response.data)
        })
        .catch((error)=> {
            Progress('0%')
            //console.log(error)
        })
      }

    //   async function FetchAllTeachers (){
    //     Progress('30%')
    //     // axios.get('https://new-softesc-backend.onrender.com/teachers')
    //     axios.get('https://new-softesc-backend.onrender.com/staff')
    //       .then((response) => {
    //         Progress('100%')
    //         setTeachers(response.data)
    //     })
    //       .catch((error) => {
    //         Progress('0%')
    //         console.error(error)});
    //   }

    const subjectData = {

        subjectName : subject,
        stage : stage,
        // typeOfTeacher : typeOfTeacher,
    }

    function resetInputs (){
        setStage()
        // setTypeOfTeacher('')
        setSubject('')
    }

    function handleSubjectSubmit (){
        setIsSubmitting(true)
        // if((stage<=-4 || stage===''||(subject==='')||(typeOfTeacher===''))){
        if((stage<=-4 || stage===''||(subject===''))){
            AlertNotice('Fill all BLANK input fields', false)
            setIsSubmitting(false)
            return
        }
        Progress('30%')
        // axios.post('https://new-softesc-backend.onrender.com/subject', subjectData)
        axios.post('https://new-softesc-backend.onrender.com/subject', subjectData)
        .then((response)=>{
            AlertNotice(response.data.message, true)
            Progress('100%')
            //console.log('subject submitted successfully')
            setRefresh(!refresh)
        })
        .catch((error)=>{
            AlertNotice(error.response.data.error, false)
            Progress('0%')
            //console.log(error)
        })
        .finally(()=>{
            setIsSubmitting(false)
        })
    }


  return (
        <div className="App">
                <SideMenu/>
                <Alert/>
                <div className="main-menu">
                    <Header/>
                    <div className="content-loader">
                        <SuperActionButtons/>
                        <div className="add-subject-form">
                            <div className="sorting" style={{maxHeight: sortingView?'4rem':'',overflow: 'hidden'}}>
                            <div className="sorting-header" onClick={toggleSortingView}>
                            <h2>Add New Subject</h2>
                            <IoIosArrowDown id='sorting-down-arrow'/>
                            </div> 
                           <div className="sorting-body"style={{display: sortingView?'none':'flex'}}>
                           <div className="input">
                                <label htmlFor="">Class: </label>
                                <select 
                                    id="mySelect"
                                    required
                                    value={stage}
                                    onChange={(e) => {setStage(e.target.value)}                }                      
                                >
                                    <option value='' disabled hidden>Select a Class </option>
                                    {/* <option value={-3}>Preschool - Nursery One</option> */}
                                    {/* <option value={-2}>Preschool - Nursery Two</option> */}
                                    <option value={-1}>Kindergarten One</option>
                                    <option value={0}>Kindergarten Two</option>
                                    <option value={1}>Class One</option>
                                    <option value={2}>Class Two</option>
                                    <option value={3}>Class Three</option>
                                    <option value={4}>Class Four</option>
                                    <option value={5}>Class Five</option>
                                    <option value={6}>Class Six</option>
                                    <option value={7}>Class Seven/ JHS 1</option>
                                    <option value={8}>Class Eight / JHS 2</option>
                                    <option value={9}>Class Nine / JHS 3</option>
                                </select>
                            </div>
                           <div className="input">
                                <label htmlFor="">Subject: </label>
                                <select 
                        id="mySelect"
                        value={subject}
                        onChange={(e)=>{setSubject(e.target.value)}}
                        >
                        <option value="" disabled hidden>Choose a subject</option>
                        {

                        (stage ===-4 )?
                        <>
                          <option value="HEALTH -NS">COLOURING -CRCH</option>
                          <option value="OWOP -NS">SCRIBBLING -CRCH</option>
                        </> : null
                       }

                        {

                        (stage >= -3 && stage <= -2)?
                        <>
                          <option value="HEALTH -NS">HEALTH -NS</option>
                          <option value="OWOP -NS">OWOP -NS</option>
                          <option value="MATHS -NS">MATHS -NS</option>
                          <option value="LANG & COMM -NS">LANG & COMM -NS</option>
                          <option value="SENSES">SENSES</option>
                          <option value="PHYSICAL REPORT -NS">PHYSICAL REPORT -NS</option>
                          <option value="CREATIVE-ARTS-NS">CREATIVE-ARTS-NS</option>
                          <option value="COGNITIVE ABILITY -NS">COGNITIVE ABILITY -NS</option>
                          {/* <option value="Twi">AAA</option> */}
                         
                        </> : null
                       }
                        {

                        (stage >= -1 && stage <= 0)?
                        <>
                          <option value="Numeracy -KG">Numeracy -KG</option>
                          <option value="Literacy  -KG">Literacy  -KG</option>
                          <option value="Science  -KG">Science  -KG</option>
                          <option value="OWOP  -KG">OWOP  -KG</option>
                          {/* <option value="HISTORY  -KG">HISTORY  -KG</option> */}
                          <option value="RME  -KG">RME  -KG</option>
                          <option value="COMPUTING  -KG">COMPUTING  -KG</option>
                          <option value="CREATIVE ARTS  -KG">CREATIVE ARTS  -KG</option>
                          <option value="FRENCH  -KG">FRENCH  -KG</option>
                          <option value="GH.LANG (TWI)  -KG">GH. LANG (TWI)  -KG</option>
                        </> : null
                       }
                        {
                          (stage > 0 && stage <= 3)?
                          <>
                          <option value="Numeracy -LP">Numeracy -LP</option>
                          <option value="Literacy  -LP">Literacy  -LP</option>
                          <option value="Science  -LP">Science  -LP</option>
                          <option value="OWOP  -LP">OWOP  -LP</option>
                          <option value="HISTORY  -LP">HISTORY  -LP</option>
                          <option value="RME  -LP">RME  -LP</option>
                          <option value="COMPUTING  -LP">COMPUTING  -LP</option>
                          <option value="CREATIVE ARTS  -LP">CREATIVE ARTS  -LP</option>
                          <option value="FRENCH  -LP">FRENCH  -LP</option>
                          <option value="GH.LANG (TWI)  -LP">GH. LANG (TWI)  -LP</option>
                          </>: null
                        }
                        {
                          (stage >=4 && stage <= 6)?
                          <>
                          <option value="Numeracy -UP">Numeracy -UP</option>
                          <option value="Literacy  -UP">Literacy  -UP</option>
                          <option value="Science  -UP">Science  -UP</option>
                          <option value="OWOP  -UP">OWOP  -UP</option>
                          <option value="HISTORY  -UP">HISTORY  -UP</option>
                          <option value="RME  -UP">RME  -UP</option>
                          <option value="COMPUTING  -UP">COMPUTING  -UP</option>
                          <option value="CREATIVE ARTS  -UP">CREATIVE ARTS  -UP</option>
                          <option value="FRENCH  -UP">FRENCH  -UP</option>
                          <option value="GH.LANG (TWI)  -UP">GH. LANG (TWI)  -UP</option>
                          </>: null
                        }

                        {
                          (stage>=7 && stage <=9)?
                          <>
                          <option value="Numeracy -JHS">Numeracy -JHS</option>
                          <option value="Literacy  -JHS">Literacy  -JHS</option>
                          <option value="Science  -JHS">Science  -JHS</option>
                          <option value="Career Tech -JHS">Career Tech -JHS</option>
                          {/* <option value="OWOP  -JHS">OWOP  -JHS</option> */}
                          <option value="SOCIAL STUDIES  -JHS">SOCIAL STUDIES  -JHS</option>
                          <option value="RME  -JHS">RME  -JHS</option>
                          <option value="COMPUTING  -JHS">COMPUTING  -JHS</option>
                          <option value="CREATIVE ARTS  -JHS">CREATIVE ARTS  -JHS</option>
                          <option value="FRENCH  -JHS">FRENCH  -JHS</option>
                          <option value="GH.LANG (TWI)  -JHS">GH. LANG (TWI)  -JHS</option>
                          </>: null
                        }
                       
                    </select>
                        </div>
                        
                        {/* <div className="input" style={{minWidth: '50%'}}>
                            <label htmlFor="">Type of Teacher: </label>
                            <div style={{display : 'flex', marginLeft : '2rem',gap :'2rem'}}>
                                <div style={{display : 'flex', alignItems : 'center', gap : '0.5rem'}}>
                                    <input 
                                        type="radio" 
                                        name="teacherType" 
                                        value="SubectTeacher" 
                                        id="option1" 
                                        onChange={(e)=>{setTypeOfTeacher(e.target.value)}}
                                        />
                                    <label for="option1" >Subject Teacher </label>
                                </div>

                                <div style={{display : 'flex', alignItems : 'center', gap : '0.5rem'}}>
                                    <input 
                                        type="radio" 
                                        name="teacherType" 
                                        value="ClassTeacher" 
                                        id="option2"
                                        onChange={(e)=>{setTypeOfTeacher(e.target.value)}}

                                        />
                                    <label for="option2">Class Teacher  </label>
                                </div>
                            </div>

                        </div> */}

                        
                           </div>
                           <div id="submit-reset-buttons">
                                <button onClick={()=>{handleSubjectSubmit(); setIsSubmitting(true)}} disabled= {isSubmitting}>Create Subject</button>
                                <button onClick={resetInputs}>Reset Inputs</button>
                            </div>

                            </div>
                            
                        </div>

{/* <br /> */}
   <div className="container">
                <table className='user-table'>
                    <Loading/>
                <thead>
                <tr>
                    <th>Subject ID</th>
                    <th id='user-name-head'>Subject Name</th>
                    <th>Subject Teacher</th>
                    {/* <th>Class Contact</th> */}
                    {/* <th>Class Tution Fees</th> */}
                    {/* <th>Num of students Paid Fees</th> */}
                    <th id='user-actions-head'>Action</th>
                </tr>
                </thead>
                <tbody>
                
                {
                    allSubjects.map((subject, index)=>( 
                    <tr key={index}>
                        <td>{subject.refNumber}</td>
                        <td id='user-name'>{subject.subjectName}</td>
                        <td>{subject.subjectTeacher}</td>
                        {/* <td></td> */}
                        {/* <td></td> */}
                        <td id='table-actions'>
                            {/* <FaEye style={{color: 'green'}}/>  */}
                            <FaEdit/> 
                            {/* <FaTrash style={{color:'red'}}/> */}
                        </td>
                    </tr>
                    )) 
                 } 
                </tbody>
            </table>
       </div>

                    </div>
                </div>
        </div>  )

}

export default AddSubject