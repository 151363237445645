import React, { useContext } from 'react'
import { FaSearch, FaEnvelope, FaBell, FaUser } from 'react-icons/fa';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { ProgressBar } from './sp-reusables';
import { UserAuthenticationContext } from '../../Contexts/AuthenticationContext';


function SPHeader() {
  let {pageName} = useContext(UserInterfaceContext)
  const {CurrentUserData} = useContext(UserAuthenticationContext)
  return (
    <div className='Header'>
      <ProgressBar/>
        <h2>STUDENT'S PORTAL : : : [ { <b style={{color: 'white', display: 'inline'}}>{pageName}</b> } ] </h2>
        <div className="header-features">
          {/* <div className='search'> <input id='header-input' type="text" placeholder='Type your searh here'/><FaSearch id='search-icon'/></div> */}
          {/* <FaEnvelope/>
          <FaBell/> */}
          <div className='user'>
            <FaUser id='user-icon'/>
            <b>{CurrentUserData?.firstName.toUpperCase()}</b>
          </div>
        </div>
    </div>
  )
}

export default SPHeader