import React from 'react'
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'

function BPInventory() {
  return (
    <div className='App'>
      <BPSideMenu/>
      <div className='main-menu'>
        <BPHeader/>
        <div  className='content-loader'>
            {/* the main menu content goes here  */}
            BPInventory
        </div>

      </div>
    </div>
  )
}

export default BPInventory