import React from "react"
import { useEffect, useState,useContext } from "react"
import { UserInterfaceContext } from "../../Contexts/ui-context"
import { FaCheckCircle, FaTimes } from "react-icons/fa"

export function Alert(){
    const {alertMessage, alertStatus, displayAlert,setDisplayAlert} =useContext(UserInterfaceContext)
    return(
      <div className='alert-notice' style={{maxWidth: displayAlert==true?'20rem':'0rem',width: displayAlert==true?'20rem':'0rem', backgroundColor: alertStatus?'green':'rgb(240, 82, 82)' }}>
        {alertStatus==true?<FaCheckCircle id='noticeIcon'/>:alertStatus==false?<FaTimes id='noticeIcon'/>:null}
        <p>{alertMessage}</p>
      </div>
    )
  }

  

  export function ProgressBar(){
    const {progressPercentage} = useContext(UserInterfaceContext)
    return(
  <div className='progressBar' >
        <div className="progressPercentage" style={{width: progressPercentage,}}>
        </div>
      </div>
    )
  }


  function reusables() {
  return (
    <>
      <ProgressBar/>
     <Alert/>
   </>
  )
}