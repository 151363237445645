import React, { useContext } from 'react'
import { FaSearch, FaEnvelope, FaBell, FaUser } from 'react-icons/fa';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { ProgressBar } from './reusables';
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext';

function Header() {
  let {pageName, smallScreenView} = useContext(UserInterfaceContext)
  const {CurrentUserData} = useContext(UserAuthenticationContext)

  return (
    <div className='Header'>
      <ProgressBar/>
        <h2>{smallScreenView?'MS':'MANAGEMENT SYSTEM : : : '} [ { <b style={{color: 'white', display: 'inline'}}>{pageName}</b> } ] </h2>
        <div className="header-features">
          {/* <div className='search'> <input id='header-input' type="text" placeholder='Type your searh here'/><FaSearch id='search-icon'/></div> */}
          {/* <FaEnvelope/> */}
          {/* <FaBell/> */}
          <div className='user'>
            <FaUser id='user-icon'/>
            <b>{CurrentUserData?.firstName.toUpperCase()}</b>
             </div>
        </div>
    </div>
  )
}

export default Header