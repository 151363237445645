import React, { useContext } from 'react'
import Header from '../Components/header'
import {SideMenu} from '../Components/sideMenu'
import construction from '../images/icons/construction-icon-38955.png'
import { UserInterfaceContext } from '../Contexts/ui-context'
function PageNotFound() {
  const {setPageName} = useContext(UserInterfaceContext)
  setPageName('ERROR!! 404')
  return (
    <div className='App'>
      {/* <SideMenu/> */}
      <div className="main-menu">
        {/* <Header/> */}
        <div className="content-loader" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <img src={construction} alt="" style={{width:'20rem'}}/>
        <h1 style={{textAlign: 'center'}}>ERROR! 404 :: THIS PAGE DOES NOT EXIST OR IS STILL UNDER CONSTUCTION</h1>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound