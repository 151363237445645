import React, { useContext, useEffect, useState } from 'react'
import { SuperActionButtons, Alert } from '../../Components/reusables'
// import SideMenu from '../../Components/sideMenu'
import { SideMenu } from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import axios from 'axios'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { Loading } from '../../Components/reusables'


function AddAcademicTerm() {
    const DefaultNumberOfWeeks = 10
    const DefaultNumberOfVacDays = 21
    const {Progress,AlertNotice,  isSubmitting, setIsSubmitting, loading, setLoading} = useContext(UserInterfaceContext)
    const [academicYear, setAcademicYear] = useState([])
    const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
    const [academicTermName, setAcademicTermName] = useState('')
    const [beginDate, setBeginDate] = useState( new Date())
    const [endDate, setEndDate] = useState('');
    const [numOfWeeks, setNumOfWeeks] = useState(DefaultNumberOfWeeks)
    const [numOfVacDays, setNumOfVacDays] = useState(DefaultNumberOfVacDays)
    const [allAcademicTerm, setAllAcademicterm] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [sortingView, setSortingView] = useState(true)

    function toggleSortingView (){
        setSortingView(!sortingView)
      }

    useEffect(()=>{
        Progress('30%')    // axios.get('https://new-softesc-backend.onrender.com/academicYear'
        axios.get('https://new-softesc-backend.onrender.com/academicYear'
        )
        .then((response)=>{
            Progress('100%')
            // ////console.log(response)
            setAcademicYear(response.data)
            // //console.log(academicYear)
        })
        .catch((error)=> {
            Progress('0%')
            //console.log(error)
        })
    },[])

    const academicTermData = {
        academicYearName:selectedAcademicYear,
        academicTermName : academicTermName,
        beginDate : beginDate,
        numOfWeeks : numOfWeeks,
        numOfVacDays :numOfVacDays,
        endDate : endDate,

        
    }

    function ResetInputs (){
        setSelectedAcademicYear('')
        setAcademicTermName('')
        setBeginDate(new Date())
        setNumOfWeeks(DefaultNumberOfWeeks)
        setNumOfVacDays(DefaultNumberOfVacDays)
    }

    useEffect(()=>{
        setIsSubmitting(false)
    },[academicTermName, selectedAcademicYear,])

    useEffect(()=> {
        const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
        const calculatedEndDate = new Date(beginDate.getTime() + numOfWeeks * millisecondsInWeek);
        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two-digit month
            const day = String(date.getDate()).padStart(2, '0'); // Ensures two-digit day
        
            return `${year}-${month}-${day}`;
        }
        setEndDate(formatDate(calculatedEndDate));
    }, [beginDate, numOfWeeks])


function handleSubmitAcademicTerm (e){
    setIsSubmitting(true)
    if(numOfWeeks<=0){
        AlertNotice('Invalid End Date', false)
        return
    }
    // e.preventDefault() 
    if(academicTermData.academicYearName !== '' && academicTermData.academicTermName !== '' && academicTermData.beginDate !== '' && academicTermData.endDate !== ''){
        Progress('30%')
    // axios.post('https://new-softesc-backend.onrender.com/academicTerm', academicTermData)
    axios.post('https://new-softesc-backend.onrender.com/academicTerm', academicTermData)
    .then((response)=>{
        AlertNotice('Academic Term added Successfully', true)
        Progress('100%')
        setRefresh(!refresh )
        setIsSubmitting(false)


    })
    .catch((error)=>{
        AlertNotice(error.response.data.error, false)
        Progress('0%')
        setIsSubmitting(false)

    })
    .finally(()=>{
        setIsSubmitting(false)
    })
    }
    else {
        AlertNotice('Complete all fields', false)
        setIsSubmitting(false)
    }
    setIsSubmitting(false)

    
}

useEffect(()=>{
    setLoading(true)
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/academicTerm')
    .then((response)=>{
        Progress('100%')
        setAllAcademicterm(response.data)
        setLoading(false)
    })
    .catch((error)=>{
        Progress('0%')
        //console.log(error)
    })
},[refresh])

  return (
    <div className="App">
    <SideMenu/>
    <Alert/>
    <div className="main-menu">
        <Header/>
        <div className="content-loader academic-term">
            <SuperActionButtons/>
            <form onSubmit={handleSubmitAcademicTerm}>
            <div className="academic-term-form">
                    <div className="sorting" style={{maxHeight: sortingView?'4rem':'',overflow: 'hidden'}}>
                    <div className="sorting-header" onClick={toggleSortingView}>
                    <h2>Add New Academic Term</h2>
                    <IoIosArrowDown id='sorting-down-arrow'/>
                    </div>  
                    <div className="sorting-body" style={{display: sortingView?'none':'flex'}}>
                    <div className="input">
                        <label htmlFor="">Academic Year:</label>
                        <select 
                            name="" 
                            id=""
                            required
                            value={selectedAcademicYear}
                            onChange={(e) => { setSelectedAcademicYear(e.target.value) }}
                        >
                            <option value="" disabled hidden>Select academic year</option>
                           
                            {academicYear.length>0? academicYear.map((year, index) => (
                                <option key={index} value={year.academicYearName}>
                                    {year.academicYearName}
                                </option>
                            )):null}
                            
                        </select>


                    </div>

                    <div className="input">
                        <label htmlFor="">Academic Term :</label>
                        <select 
                            name="" 
                            id=""
                            required
                            value={academicTermName}
                            onChange={(e)=>{setAcademicTermName(e.target.value)}}
                            >
                            <option value="" disabled hidden>Choose Term</option>
                            <option value="FIRST">First </option>
                            <option value="SECOND">Second </option>
                            <option value="THIRD">Third </option>
                        </select>

                    </div>

                    {/* for the begin date  */}
                    <div className="input">
                        <label htmlFor="">Begin Date</label>
                        <input 
                            type="date" 
                            required
                            value={beginDate?beginDate.toISOString().split('T')[0]: null}  // Convert to string
                            onChange={(e) => { setBeginDate(new Date(e.target.value)); }}
                        />
                    </div>

                    {/* for the number of weeks  */}
                    <div className="input">
                        <label htmlFor="">Number of Weeks</label>
                        <input 
                            type="number"
                            required
                            value={numOfWeeks}
                            onChange={(e) => { setNumOfWeeks(e.target.value); }}
                        />
                    </div>

                    {/* for the end date   */}
                    <div className="input">
                        <label htmlFor="">End Date: </label>
                        <input 
                            type="date"
                            required
                            // readOnly
                            value={endDate}  // Convert to string
                            onChange={(e) => { setEndDate(e.target.value); }}
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="">Vacation Days :</label>
                        <input 
                            type="number"
                            required
                            value={numOfVacDays}
                            onChange={(e) => { setNumOfVacDays(e.target.value); }}
                        />
                    </div>
                    </div>
                    <div id="submit-reset-buttons">
                        <button type='submit'onClick={()=>{handleSubmitAcademicTerm(); setIsSubmitting(true)}} disabled={isSubmitting} >Create Term</button>
                        <button type = 'button' onClick={ResetInputs} >Reset Inputs</button>
                    </div>
                    </div>
                    
                </div>
            </form>
            {/* <br /> */}
            

            <div className="container">
            <h2 className='input sticky-left'>TERMLY SUMMARIES</h2>
                        <table className='user-table'>
                            <Loading/>
                            <thead>
                               <tr>
                               {/* <th>ACADEMIC REF</th> */}
                               <th>ACADEMIC YEAR</th>
                                <th id='user-name-head'>TERM NAME</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                                <th style={{color: ''}}>TOTAL INCOME (GHS)</th>
                                <th style={{color: ''}}>TOTAL EXPENDITURE (GHS)</th>
                                <th>TOTAL BALANCE </th>
                                {/* <th>TT STUDENTS ADMITTED</th> */}
                                {/* <th>TT STUDENTS LEFT</th> */}
                                <th>Vacation Days</th>
                                <th>STATUS</th>
                                {/* <th id='user-actions-head'>ACTION </th> */}
                               </tr>
                            </thead>
                            <tbody>
                               {
                                allAcademicTerm?.map((term, index)=>(
                                    <tr key={index}>
                                            <td>{term.academicYearName}</td>
                                            <td id='user-name'>{term.academicTermName}</td>
                                            <td>{term.beginDate}</td>
                                            <td >{term.endDate}</td>
                                            <td></td>
                                            <td></td>
                                            {/* <td> </td> */}
                                            <td> </td>
                                            <td>{term.numOfVacDays} </td>
                                            <td style={{color : term.status==='CURRENT'?'#031c3b': '#f47c2c', fontWeight: 'bolder'}}>{term.status} </td>
                                            {/* <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td> */}
                               </tr>
                                ))
                               }
               
                               
                            </tbody>
                        </table>
                </div>
        </div>
    </div>
</div>   )
}

export default AddAcademicTerm