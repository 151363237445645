import axios from "axios";
import { createContext, useEffect, useState  } from "react";


const UserAuthenticationContext = createContext();

const UserAuthenticationProvider = ({children})=>{

 
  // const [login, setLogin] = useState(false)
  // const [currentUser, setCurrentUser] = useState({})
  // const [userRole, setUserRole] = useState('')
  
  const [attemptLogout, setAttempLogout] = useState(false)
  const [CurrentUserData, setCurrentUserData] = useState(localStorage.getItem('CurrentUserData') ? JSON.parse(localStorage.getItem('CurrentUserData')):null)
  const [userName, setUserName] = useState('')
  const [userStage, setUserStage] = useState()
  const [currentUserId, setCurrentUserId] = useState()



  const AutoLogOut = setTimeout(()=>{
  
    localStorage.clear()
  },100*60*1000)
  // const AutoLogOut = setTimeout(()=>{setLogin(false)},6*1000)
 
  return (
    <UserAuthenticationContext.Provider value={
      {
        // login, 
        // setLogin, 

        // userRole,
        // setUserRole,

        attemptLogout,
        setAttempLogout,

        CurrentUserData,
        setCurrentUserData,

        userName,
        setUserName,
        userStage,
        setUserStage,

        currentUserId,
        setCurrentUserId,


       
        }}>
      {children}
    </UserAuthenticationContext.Provider>
  )
}

export {UserAuthenticationContext, UserAuthenticationProvider}