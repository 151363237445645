import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import './classes.css'
import { NavLink, Navigate } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { UserDataContext } from '../../Contexts/user-data';
import { StageContext } from '../../Contexts/stage-context';

function Classdetails() {
  const {setPageName} = useContext(UserInterfaceContext)
  const [stageData, setStageData] = useState([])
  const {setUserId} = useContext(UserDataContext)
  const {Progress, setShowDeletePallet} = useContext(UserInterfaceContext)
  const {selectedStage, } = useContext(StageContext)
  const [studentData, setStudentData] = useState([])

  setPageName('CLASSROOM')





async function FetchClassData (){
  if(selectedStage===''){
    return
  }
  else {
    axios.get('https://new-softesc-backend.onrender.com/stages/stageTerm',
    {
      params : {
      stage : selectedStage
    }}
    )
    .then((response)=>{
        ////console.log(response.data)
        setStageData(response.data)
    })
    .catch((error)=>{
        //console.log(error)
    })
  }
}

async function FetchClassStudentData (){
  //console.log(selectedStage)
 if(selectedStage===''){
  return
 }
 else{
  Progress('30%')
  axios.get('https://new-softesc-backend.onrender.com/students',
  {
    params : {
    stage : selectedStage
  }}
  )
  .then((response)=>{
    Progress('100%')
      ////console.log(response.data)
      setStudentData(response.data)
  })
  .catch((error)=>{
      //console.log(error)
  })
 }
}

  useEffect(()=>{
    FetchClassData()
    FetchClassStudentData()
},[])


function GenerateStageName (stage){
  if(stage===-4){
      return('PRE-SCHOOL-CRECHE')
  }
  else if(stage===-3){
      return('NURSERY 1')
  }
  else if(stage===-2){
      return('NURSERY 2')
  }
  else if(stage===-1){
      return('KINDERGARTEN 1')
  }
  else if(stage===0){
      return('KINDERGARTEN 2')
  }
  else if(stage===1){
      return('CLASS 1')
  }
  else if(stage===2){
      return('CLASS 2')
  }
  else if(stage===3){
      return('CLASS 3')
  }
  else if(stage===4){
      return('CLASS 4')
  }
  else if(stage===5){
      return('CLASS 5')
  }
  else if(stage===6){
      return('CLASS 6')
  }
  else if(stage===7){
      return('CLASS 7 / JHS 1')
  }
  else if(stage===8){
      return('CLASS 8 / JHS 2')
  }
  else if(stage===9){
      return('CLASS 9 / JHS 3')
  }
}


if(!selectedStage){
  return <Navigate to='/AllClasses'/>
}

  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <div className='class-details content-loader'>
          <div className="class-actions">
          {/* <NavLink to='/UpdateClass'><button>Update Class</button></NavLink> */}
          {/* <div className="action-buttons">
          <NavLink to='/AddClass'><button>Add New Class</button></NavLink>
          <NavLink to='/Attendance'><button>Take Attendance</button></NavLink>
          <NavLink to='/Grades'><button>Add Grades</button></NavLink>
          </div> */}
        </div>

        <div className="details-header ">
        {stageData.map((stageData, index)=>(
        
            <div className="details personal-info" key={index}>
              <h3>Current Class Details <b style={{color: 'white'}}>{GenerateStageName(stageData.stage)}</b> </h3> 
              <label htmlFor="">Class  Number ::::::::::::::: {(stageData.stage)} </label> 
              <label htmlFor="">Name of Teacher :::::::::: {stageData.stageTeacher ? stageData.stageTeacher.name : <b style={{color:'red'}}> NO CLASS TEACHER</b> }</label> 
              <label htmlFor="">Number of Students :::: {stageData.numOfBoys + stageData.numOfGirls} </label> 
              <label htmlFor="">Number of Boys ::::::::::: {stageData.numOfBoys} </label> 
              <label htmlFor="">Number of Girls :::::::::::: {stageData.numOfGirls} </label> 
              <label htmlFor="">School fees :::::::::::::::::::: {stageData.schoolFees} </label> 
              <label htmlFor="">Total school fees ::::::::::: {stageData.totalFees} </label> 
              <label htmlFor="">Paid school fees :::::::::::: {stageData.totalPaid}</label> 

          </div>
        )) }
        
      
        </div>
        <br />
        <div className="container">
        <table className='user-table'>
              <thead>
                <tr>
                  <th>Student ID: </th>
                  <th>Photo</th>
                  <th id='user-name-head'>Name</th>
                  <th>Class </th>
                  <th>Gender</th>
                  <th>House</th>
                  {/* <th>Class Teacher</th> */}
                  {/* <th>
                    School Fees
                    <div id='inner-th'>
                      <em id='paid'>Paid (GHS)</em>  
                      <em id='balance'>Balance(GHS)</em>  
                    </div></th> */}
                  <th>Address</th>
                  <th>Date of Birth</th>
                  <th>Parent / Guardian</th>
                  <th>Telephone</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th id='user-actions-head'>Action</th>
                  {/* Add more header cells as needed */}
                </tr>
              </thead>
              <tbody>
                {studentData.length>0?(studentData.map((studentData, index)=>(
                    <tr key={index}>
                    <td id='no-wrap'>{studentData.refNumber}</td>
                    <td id='no-wrap'>N/A</td>
                    <td id='user-name'>{studentData.firstName + " " + studentData.lastName}</td>
                    <td id='no-wrap'>{studentData.stage}</td>
                    <td id='no-wrap'>{studentData.gender}</td>
                    <td id='no-wrap'>{studentData.house}</td>
                    {/* <td id='no-wrap'>{studentData.classTeacher}</td> */}
                    {/* <td id='inner-td'><em id='paid'>{studentData.paidFeesAmount}</em> <em id='balance'>{studentData.feesBalance}</em></td> */}
                    <td id='no-wrap'>{studentData.presentAddress}</td>
                    <td id='no-wrap'>{studentData.dob}</td>
                    <td id='no-wrap'>F:{studentData.fatherName }<br /> M:{studentData.motherName}</td>
                    <td id='no-wrap'>F:{studentData.fatherPhone }<br /> M:{studentData.motherPhone}</td>
                    <td id='no-wrap'>{studentData.email}</td>
                    <td id='no-wrap'>{studentData.userStatus}</td>
                    {/* <td id='table-actions'><NavLink to='/StudentDetails'><FaEye id='action-see' onClick={()=>{setUserId(studentData.refNumber)}}/></NavLink> <FaEdit/> <FaTrash id='action-delete'/></td> */}
                    <td id='table-actions'>
                      <NavLink to="/StudentDetails" onClick={async() => await setUserId(studentData.refNumber)}>
                          <FaEye id="action-see" />
                        </NavLink>
                      <NavLink to='/StudentUpdate' onClick={async()=> await setUserId(studentData.refNumber)}>
                          <FaEdit id='action-update'/> 
                      </NavLink>
                      <NavLink>
                        <FaTrash id='action-delete' onClick={()=>{
                          setShowDeletePallet(true); 
                          setUserId(studentData.refNumber)
                          }}/>
                      </NavLink>
                    </td>
                  </tr>
                  ))):
                  (<tr >
                    <td colSpan ='14'style={{padding:'1rem', color:'red'}}>No Students in the Class</td>
                  </tr>)
                }
        
              </tbody>
        </table>
        </div>
          </div>
        </div>
      </div>
    
  )
}

export default Classdetails