import React from 'react'
import SideMenu from '../../Components/sideMenu'
import Header from '../../Components/header'

function StudentPromotion() {
  return (
    <div>StudentPromotion</div>
  )
}

export default StudentPromotion