import React, { useContext, useState, useEffect, useId } from 'react'
import axios from 'axios';
import { FaUserGraduate, FaUser,FaUsers,} from 'react-icons/fa';
// import {SideMenu} from '../../Components/sideMenu'
// import Header from '../../Components/header'
// import { UserDataContext } from '../../Contexts/user-data';
// import { UserInterfaceContext } from '../../Contexts/ui-context';
// import { StudentActions } from '../../Components/reusables';
import { Navigate } from 'react-router-dom';
import { StudentActions } from './TPCOMPONENTS/tp-reusables';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { UserDataContext } from '../Contexts/user-data';
import TPHeader from './TPCOMPONENTS/tp-header';
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu';



function TPStudentDetails() {
  const {Progress} = useContext(UserInterfaceContext)

  const {userId, } = useContext(UserDataContext)
  const [userData, setUserData] = useState([])
  const [userFinancialData, setUserFinancialData] = useState([])

 
  useEffect(()=>{
    //console.log('student id in details : ', userId)
    Progress('30%')
        // axios.get('https://new-softesc-backend.onrender.com/students'
    axios.get('https://new-softesc-backend.onrender.com/students'
    ,{
      params:{
        userId : userId,
      }
    })
  .then((response)=>{
    Progress('100%')
    setUserData(response.data)
    ////console.log(response.data)
    //console.log('userdata',userData)
  })
  .catch((error)=>{
    Progress('0%')
    //console.log(error)
  })
  },[userId])


  useEffect(()=>{
    //console.log('student id in details : ', userId)
    Progress('30%')
        // axios.get('https://new-softesc-backend.onrender.com/students'
    axios.get('https://new-softesc-backend.onrender.com/finance/studentTerm'
    ,{
      params:{
        refNumber : userId,
      }
    })
  .then((response)=>{
    Progress('100%')
    setUserFinancialData(response.data)
    ////console.log(response.data)
    //console.log('userdata',userData)
  })
  .catch((error)=>{
    Progress('0%')
    //console.log(error)
  })
  },[userId])

  
 
  if(userId===''){
    return <Navigate to='/AllStudent'/>
  }
  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('CLASS 1')
    }
    else if(stage===2){
        return('CLASS 2')
    }
    else if(stage===3){
        return('CLASS 3')
    }
    else if(stage===4){
        return('CLASS 4')
    }
    else if(stage===5){
        return('CLASS 5')
    }
    else if(stage===6){
        return('CLASS 6')
    }
    else if(stage===7){
        return('CLASS 7 / JHS 1')
    }
    else if(stage===8){
        return('CLASS 8 / JHS 2')
    }
    else if(stage===9){
        return('CLASS 9 / JHS 3')
    }
  }
  return (
    <div className="App">
        <TPSideMenu/>
        <div className="main-menu">
          <TPHeader/>
          <div className='conent-loader student-details'>
            <StudentActions/>
     <h3 className='class-level'>STUDENT DETAILS : <b style={{color: 'yellow'}}>{userId}</b></h3>
      
      <div>
            <div className="info">
            <div className="img-container">
              <FaUserGraduate id='user-details-icon'/>
            </div>
            <div className="personal-info">
              <label htmlFor="">Student ID : {userData[0]?.refNumber} </label>
              <label htmlFor="">Name : {userData[0]?.fullName} </label>
              <label htmlFor="">{GenerateStageName(userData[0]?.stage)} </label>

            </div>
            </div>
            <br />
        {userData.length>1? <p style={{color: 'red'}}>Choose Student from student Data</p>: userData.map((userData, index)=>(
          <div key={index} className='info'>
            

              <div  className="personal-info">
                  <h3>Personal Information</h3>
                 
                  <label htmlFor="">Gender : {userData.gender} </label>
                  <label htmlFor="">Class Teacher : {userData.classTeacher} </label>
                  {/* <label htmlFor="">School Fees : </label> <label htmlFor="">Paid 300.00 out of 450.00</label> */}
                  <label htmlFor="">House : {userData.house} </label>
                  <label htmlFor="">Address : {userData.presentAddress} </label>
                  <label htmlFor="">Date of Birth : {userData.dob} </label>
                  <label htmlFor="">Father's Name : {userData.fatherName} </label>
                  <label htmlFor="">Father's Contact  : {userData.fatherPhone}  </label>
                  <label htmlFor="">Mother's Name : {userData.motherName} </label>
                  <label htmlFor="">Mother's Contact : {userData.motherPhone} </label>
                  <label htmlFor="">Email : {userData.email}  </label>
              </div>
              
              <div className="financial-info">
              <h3>Financial Information</h3>

              {userFinancialData.length>0?userFinancialData.map((financeData, index)=>(
                <div key={index} style={{position : 'relative',marginBottom: '1rem' }}>
                <h3 htmlFor="" style={{color: 'white', position:'sticky', top: '2.2rem', zIndex : '2'}}>Year : {financeData.academicYear} | Term : {financeData.academicTerm}  </h3>
                {/* <label htmlFor="">Academic Term :{financeData.academicTerm}  </label> <br /> */}
                <b>Stage :.................. {GenerateStageName(financeData.stageValue)}  </b><br />
                <b>Total Fees :........... {financeData.financialData.totalFees?financeData.financialData.totalFees:0} </b><br />
                <b>Total Paid :........... {financeData.financialData.paidFees?financeData.financialData.paidFees:0}</b><br />
                <b>Total Owing :....... {financeData.financialData.totalFees - financeData.financialData.paidFees} </b><br />
                <b> Financial Status :. {(financeData.financialData.totalFees - financeData.financialData.paidFees)>0? <b style={{color: 'red'}}>OWING</b> : <b style={{color:'green'}}>PAID</b> } </b>
                <br /> <br /><hr/>
            </div>
              ))    
              :null}
              </div>
              

              <div className="other-info">
                  <h3>Other Info</h3>
                  <label htmlFor="">Status : {userData?.userStatus} </label>
              </div>
          </div>
       ))
      }
      </div>
    </div>
        </div>
      </div>
    
  )
}

export default TPStudentDetails