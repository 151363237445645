import React, { useContext } from 'react'
import { FaSearch, FaEnvelope, FaBell, FaUser } from 'react-icons/fa';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { ProgressBar } from './tp-reusables';
import { UserAuthenticationContext } from '../../Contexts/AuthenticationContext';

function TPHeader() {
  let {pageName, smallScreenView} = useContext(UserInterfaceContext)
  const {CurrentUserData} = useContext(UserAuthenticationContext)
  
  
  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('CLASS 1')
    }
    else if(stage===2){
        return('CLASS 2')
    }
    else if(stage===3){
        return('CLASS 3')
    }
    else if(stage===4){
        return('CLASS 4')
    }
    else if(stage===5){
        return('CLASS 5')
    }
    else if(stage===6){
        return('CLASS 6')
    }
    else if(stage===7){
        return('CLASS 7 / JHS 1')
    }
    else if(stage===8){
        return('CLASS 8 / JHS 2')
    }
    else if(stage===9){
        return('CLASS 9 / JHS 3')
    }
  }

  return (
    <div className='Header'>
      <ProgressBar/>
      <h2>{smallScreenView?'TP': GenerateStageName(CurrentUserData?.stage)+' PORTAL : : : '} [ { <b style={{color: 'white', display: 'inline'}}>{pageName}</b> } ] </h2>

        {/* <h2>CLASS TEACHERS PORTAL : : : [ { <b style={{color: 'white', display: 'inline'}}>{pageName}</b> } ] </h2> */}
        <div className="header-features">
          {/* <div className='search'> <input id='header-input' type="text" placeholder='Type your searh here'/><FaSearch id='search-icon'/></div> */}
          {/* <FaEnvelope/> */}
          {/* <FaBell/> */}
          <div className='user'>
            <FaUser id='user-icon'/>  
            <b>{CurrentUserData?.firstName.toUpperCase()}</b></div>
        </div>
    </div>
  )
}

export default TPHeader