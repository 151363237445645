import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import '../student/user.css'
import {SideMenu} from '../../Components/sideMenu';
import Header from '../../Components/header';
import { UserInterfaceContext } from '../../Contexts/ui-context'; 


function Allparent() {
  const {setPageName} = useContext(UserInterfaceContext)
  setPageName('PARENTS')
  return (
    <div className="App">
    <SideMenu/>
    <div className="main-menu">
      <Header/>
      <div className='all-users content-loader'>
      <div className='user-features'>
      <h2>Parent's Management Board</h2>


        <div className="sorting">
          <h2 id='sort-header'>Sort By Ward's Details:</h2>
          <div className='sorting-body'>
          <div className="input">
            <label htmlFor="">Name: </label><input type="text" placeholder='Enter Student Name'/>
          </div>
          <div className="input">
            <label htmlFor="">Class: </label>
            <select id="mySelect" >
                <option value="option1">All </option>
                <option value="option2">Preschool - Nursery 1</option>
                <option value="option2">Preschool - Nursery 2</option>
                <option value="option2">Preschool - Kingdergarten 1</option>
                <option value="option2">Preschool - Kingdergarten 2</option>
                <option value="option3">Class 1</option>
                <option value="option3">Class 2</option>
                <option value="option3">Class 3</option>
                <option value="option3">Class 4</option>
                <option value="option3">Class 5</option>
                <option value="option3">Class 6</option>
                <option value="option3">Class 7 / JHS 1</option>
                <option value="option3">Class 8 / JHS 2</option>
                <option value="option3">Class 9 / JHS 3</option>
            </select>

          </div>

         

          </div>
        </div>
      </div>

      <div className="container">
          <table className='user-table'>
              <thead>
                <tr>
                  <th>Roll</th>
                  <th>Photo</th>
                  <th id='user-name-head'>Name</th>
                  <th>Phone </th>
                  <th>Email</th>
                  <th>Number of wards</th>
                  <th>Names of Wards </th>
                  <th>Address</th>
                  <th>Status</th>
                  <th id='user-actions-head'>Action</th>
                  {/* Add more header cells as needed */}
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                <tr>
                  <td>one</td>
                  <td>two</td>
                  <td id='user-name'>three</td>
                  <td>four</td>
                  <td>five</td>
                  <td>six</td>
                  <td>seven</td>
                  <td>eight</td>
                  <td>nine</td>
                  <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td>
                </tr>
                
                
                
                
              </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
    
  )
}

export default Allparent