import React from 'react'
import Header from '../../Components/header'
import {SideMenu} from '../../Components/sideMenu'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import { useContext, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { LibraryActions } from '../../Components/reusables'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'
import './library.css'

function AllBooks() {
  const {setPageName} = useContext(UserInterfaceContext)
  setPageName('LIBRARY')


  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }


  return (
    <div className="App">
      <SideMenu/>
      <div className="main-menu">
        <Header/>
        <div className="library content-loader">
          <LibraryActions/>
            <div className="sorting sticky-left">
              <div 
                className="sorting-header" 
                onClick={toggleSortingView}
                >
                <h2>Sort By:</h2>
                <IoIosArrowDown id='sorting-down-arrow'/>

              </div>
              <div className="sorting-body" style={{display: sortingView?'none':'flex'}}>
              <div className="input">
                  <label htmlFor="">Book Title : </label>
                  <input type="text" />
                </div>



                <div className="input">
                  <label htmlFor="">Category : </label>
                  <select name="" id="">
                    <option value="" hidden disabled>Select a category</option>
                    <option value="a" >Fiction</option>
                    <option value="b" >Non-Fiction</option>
                    <option value="c" >Science</option>
                    <option value="d" >Maths</option>
                    <option value="d" >Social Studies</option>
                    <option value="d" >Drama</option>
                    <option value="d" >OWOP</option>
                    <option value="d" >LANGUAGES</option>
                    <option value="d" >ICT</option>
                    <option value="d" >RELIGION AND BELIEFS</option>
                    <option value="d" >HISTORY</option>
                    <option value="d" >RESEARCH</option>
                    <option value="d" >NEWSPAPER</option>
                    <option value="d" >MAGAZINE</option>
                    <option value="d" >Others</option>
                  </select>
                </div>



                <div className="input">
                  <label htmlFor="">Status : </label>
                    <select name="" id="">
                    <option value="" hidden disabled>Select a condition</option>
                    <option value="d" >All </option>
                    <option value="d" >Available </option>
                    <option value="h" >Overdue</option>
                    <option value="e" >Checked out</option>
                    <option value="e" >On hold</option>
                    <option value="e" >Missing</option>
                    </select>
                </div>
                
               
              </div>
            </div>
          {/* <h2>The library is still under construction</h2> */}
          <div className="container">
          <table className='user-table'>
            <thead>
              <th>Book Id</th>
              <th id='user-name-head'>Title</th>
              <th>ISBN</th>
              <th>Publisher</th>
              <th>Publication Date</th>
              <th>Genre / Category</th>
              <th>Availability</th>
              <th>Quantity</th>
              <th>Number On Shelf</th>
              <th>Number Out on Lending</th>
              <th>Condition</th>
              <th>Cover Image</th>
              <th id='user-actions-head'>Action</th>
            </thead>
            <tbody>
              <tr>
                <td>one</td>
                <td id='user-name'>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td>one</td>
                <td id='table-actions'><FaEye id='action-see'/> <FaEdit/> <FaTrash id='action-delete'/></td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllBooks