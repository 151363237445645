import React, { useContext, useState,useEffect } from 'react'
import { SuperActionButtons, Alert } from '../../Components/reusables'
import { SideMenu } from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import axios from 'axios'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { StageContext } from '../../Contexts/stage-context'
import { Navigate } from 'react-router-dom'
import { IoIosArrowDown } from 'react-icons/io';
import { Loading } from '../../Components/reusables'
function AddClass() {
    const [teachers, setTeachers] = useState([]);
    const {Progress, AlertNotice, isSubmitting, setIsSubmitting, loading, setLoading} = useContext(UserInterfaceContext)
    const [stageValue, setClassValue] = useState()
    const [schoolFees, setSchoolFees] = useState()
    const [selectedTeacher, setSelectedTeacher] = useState('')
    const [classData, setClassData] = useState([])
    const {selectedStage, setSelectedStage} = useContext(StageContext)
    const [editedSchoolFees, setEditedSchoolFees] = useState('')
    const [editedStageTeacher, setEditedStageTeacher] = useState('')
    const [toggleClassTableEditPallet, setToggleClassTableEditPallet] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [sortingView, setSortingView] = useState(true)

    function toggleSortingView (){
        setSortingView(!sortingView)
      }


    useEffect(() => {
        Progress('30%')
        // axios.get('https://new-softesc-backend.onrender.com/teachers')
        axios.get('https://new-softesc-backend.onrender.com/staff',{
            params:{
                staffCategory : 'teaching'
            }
        })
          .then((response) => {
            Progress('100%')
            setTeachers(response.data)
            // ////console.log(response.data)
        })
          .catch((error) => {
            Progress('0%')
            console.error(error)});
      }, []);

    
      const newStageData = {
        stageValue: stageValue,
        schoolFees: schoolFees,
        stageTeacher: selectedTeacher
      };

      const editedStageData = {
        schoolFees : editedSchoolFees,
        stageTeacher : editedStageTeacher,
      }

    function resetInputs (){
        setClassValue('')
        setSchoolFees('')
        setSelectedTeacher('')
        setEditedSchoolFees('')
        setEditedStageTeacher('')
        

    }
    useEffect(()=>{
        resetInputs()
    },[refresh, toggleClassTableEditPallet])
   
    function handleClassSubmit (){
        setIsSubmitting(true)
        if(newStageData.stageValue!=='' && newStageData.schoolFees!=='' && newStageData.selectedTeacher !==''){
           if(newStageData.schoolFees>0&&(newStageData.stageValue>=-4 && newStageData.stageValue<=15)){
            Progress('30%')
            // axios.post('https://new-softesc-backend.onrender.com/stages',newStageData)
            axios.post('https://new-softesc-backend.onrender.com/stages',newStageData)
            .then((response)=>{
                AlertNotice(response.data.message, true)
                Progress('100%')
                setRefresh(!refresh)
    
            })
            .catch((error)=>{
                AlertNotice(error.response.data.error, false)
                Progress('0%')
                // resetInputs(!refresh)
            })
            .finally(() => {
                // Enable the button after the operation completes
                setIsSubmitting(false);
            });
           }
           else {
            AlertNotice('Invalid / Unrealistic Input values', false)
            setIsSubmitting(false);


           }
        }
        else {
            AlertNotice('Complete All fields', false)
            setIsSubmitting(false);

        }
        setIsSubmitting()
    }




    const filteredEditedStageData = Object.entries(editedStageData).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '' && value !==NaN) {
            acc[key] = value;
        }
        return acc;
    }, {});


    function HandleSubmitStageEdit (e){
        //console.log(filteredEditedStageData)
        if(selectedStage==='' || selectedStage==NaN || selectedStage===undefined){
            setToggleClassTableEditPallet(true)
            console.log(selectedStage)
            return
        }
        e.preventDefault()
        if(!editedSchoolFees && editedStageTeacher===''){
            AlertNotice(' Empty Class Update ', false)
            return
        }
        Progress('30%')
        // axios.put(`https://new-softesc-backend.onrender.com/stages/${selectedStage}`,editedStageData)
        axios.put(`https://new-softesc-backend.onrender.com/stages/${selectedStage}`,filteredEditedStageData)
        .then((response)=>{
            AlertNotice(response.data.message, true)
            Progress('100%')
            setToggleClassTableEditPallet(true)

        })
        .catch((error)=>{
            AlertNotice(error.response.data.error, false)
            Progress('0%')
            //console.log(error)
        })
    }

    useEffect(()=>{
        setLoading(true)
        axios.get('https://new-softesc-backend.onrender.com/stages/stageTerm')
        .then((response)=>{
            // ////console.log(response.data)
            setClassData(response.data)
            setLoading(false)
        })
        .catch((error)=>{
            //console.log(error)
        })
    },[refresh, toggleClassTableEditPallet])


    useEffect(()=>{
        setIsSubmitting(false)
    },[stageValue, schoolFees])


    function GenerateStageName (stage){
        if(stage===-4){
            return('PRE-SCHOOL-CRECHE')
        }
        else if(stage===-3){
            return('NURSERY 1')
        }
        else if(stage===-2){
            return('NURSERY 2')
        }
        else if(stage===-1){
            return('KINDERGARTEN 1')
        }
        else if(stage===0){
            return('KINDERGARTEN 2')
        }
        else if(stage===1){
            return('CLASS 1')
        }
        else if(stage===2){
            return('CLASS 2')
        }
        else if(stage===3){
            return('CLASS 3')
        }
        else if(stage===4){
            return('CLASS 4')
        }
        else if(stage===5){
            return('CLASS 5')
        }
        else if(stage===6){
            return('CLASS 6')
        }
        else if(stage===7){
            return('CLASS 7')
        }
        else if(stage===8){
            return('CLASS 8')
        }
        else if(stage===9){
            return('CLASS 9')
        }
    }

  return (
    <div className="App">
    <SideMenu/>
    <Alert/>
    <div className="main-menu">
        <Header/>
        <div className="add-class content-loader">
            <SuperActionButtons/>
            {
            toggleClassTableEditPallet?<div className="add-class-form ">
            <div className="sorting" style={{maxHeight: sortingView?'4rem':'',overflow: 'hidden'}}>
                <div className="sorting-header" onClick={toggleSortingView}>
                <h2>Add New Class</h2>
                    <IoIosArrowDown id='sorting-down-arrow'/>
                    </div>
            <div className="sorting-body"  style={{display: sortingView?'none':'flex'}}>
            <div className="input">
                <label htmlFor="">Class Level : </label>
                <input 
                    type="number" 
                    value={stageValue}
                    min={-4}
                    required
                    max={15}
                    placeholder='eg. 1'
                    onChange={(e) => setClassValue(e.target.value)}
                    />
            </div>
            <div className="input">
                <label htmlFor="">School Fees : </label>
                <input 
                    type="number" 
                    required
                    placeholder='School Fees Amount'
                    value={schoolFees}
                    onChange={(e) => setSchoolFees(e.target.value)}
                    />

            </div>
            <div className="input">
                <label htmlFor="">Teacher :</label>
                <select
                    // disabled
                    value={selectedTeacher}
                    onChange={(e) => setSelectedTeacher(e.target.value)}
                >
                    <option value="" >Select a class teacher</option>
                    {teachers.map((teacher,index) => (
                        <option key={index} value={teacher.refNumber}>
                            {teacher.firstName + ' ' + teacher.lastName}
                        </option>
                    ))}
                </select>
            </div>
            </div>


            <div id="submit-reset-buttons">
                <button onClick={()=>{handleClassSubmit();setIsSubmitting(true)}} disabled = {isSubmitting} >Create Class</button>
                <button onClick={resetInputs}>Reset Inputs</button>
            </div>
            </div>
        </div>:null
            }
            {/* <br /> */}
            {toggleClassTableEditPallet?
            <>

                <div className="container">
                <h2 className='input sticky-left'>CLASSROOM SUMMARIES</h2>

                <table className='user-table'>
                    <Loading/>
                    <thead>
                        <tr>
                        <th>Class ID</th>
                        <th id='user-name-head'>Level</th>
                        <th>Teacher</th>
                        {/* <th>Contact</th> */}
                        <th>Class size </th>
                        <th>Boys</th>
                        <th>Girls</th>
                        <th>School Fees</th>
                        <th>Total Fees Owing </th>
                        <th>Total Fees Paid</th>
                        <th id='user-actions-head'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            classData.map((stage, index)=>(
                            <tr key={index}>
                                <td>{stage.stage}</td>
                                <td id='user-name'>{GenerateStageName(stage.stage)}</td>
                                <td id='no-wrap'>{stage.stageTeacher?.name}</td>
                                {/* <td>{stage.stageContact}</td> */}
                                <td>{stage.numOfBoys + stage.numOfGirls}</td>
                                <td>{stage.numOfBoys}</td>
                                <td>{stage.numOfGirls}</td>
                                <td>{stage.schoolFees}</td>
                                <td>{stage.totalFees}</td>
                                <td>{stage.totalPaid}</td>
                                <td id='table-actions'>
                                {/* <FaEye/>  */}
                                    <FaEdit style={{color: 'white'}} onClick={()=>{
                                       
                                        setSelectedStage(stage.stage)
                                        if(stage.stage===0){
                                            setSelectedStage(0)
                                        }
                                        
                                        setToggleClassTableEditPallet(false)}}
                                    />
                                    {/* <FaTrash style={{color:'red'}}/> */}
                                    </td>
                            </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>
            </>:null
        
            
        }
        {toggleClassTableEditPallet===false?
    <div className="edit-class">
    <div className="sorting" style={{gap: '0.5rem', position: 'relative'}}>
       
        <h2>Update Stage {selectedStage? <b style={{color : '#031c3a'}}>[{GenerateStageName(selectedStage)}]</b> :null}</h2>
        <div style={{ display: 'flex', justifyContent : 'center', alignItems : 'center',  color : 'yellow', background: '#031c3a', paddingInline : '0.5rem', paddingTop: '0rem', height : '1.5rem', borderRadius : '50%', cursor : 'pointer', position : 'absolute', top : '0.5rem', right : '0.5rem'}} onClick={()=>{setToggleClassTableEditPallet(true)}}>X</div>

       <form action="">
       <div className="sorting-body">
            <div className="input">
                <label htmlFor="">Class Teacher :</label>
                <select
                        // disabled
                        value={editedStageTeacher}
                        onChange={(e) => setEditedStageTeacher(e.target.value)}
                    >
                        <option value="" >Select a class teacher</option>
                        {teachers.map((teacher,index) => (
                            <option key={index} value={teacher.refNumber}>
                                {teacher.refNumber + ' - ' + teacher.firstName + ' '+teacher.lastName}
                            </option>
                        ))}
                    </select>
            </div>
            <div className="input">
                <label htmlFor="">School Fees</label>
                <input 
                value={editedSchoolFees}
                onChange={(e)=>{setEditedSchoolFees(e.target.value)}}
                type="Number" />
            </div>
        </div>
        <br />
        <button type='submit' onClick={HandleSubmitStageEdit}>Submit</button>

       </form>
    </div>
</div>: null    
    }
        
           
        </div>
        
    </div>
</div>   )
}

export default AddClass