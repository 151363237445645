import React from 'react'
import Header from '../Components/header'
import SideMenu from '../Components/sideMenu'
import construction from '../images/icons/construction-icon-38955.png'
import { ProcessingPage } from '../Components/processing-gif'
function Settings() {
  return (
    <div className='App'>
      <div className="main-menu">
        <div className="content-loader" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <h1 style={{textAlign: 'center'}}>ACCOUNT SETTINGS</h1>
        <p>No settings available for now. check again later </p>
        <ProcessingPage/>
        this 
        </div>
      </div>
    </div>
  )
}

export default Settings