import React, { useState,  } from 'react'
import axios from 'axios'
import '../Components/components.css'
import logo from '../images/icons/School Crest.png'
import loadergif from '../images/icons/Spinner@1x-1.0s-200px-200px (4).gif'
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

export default function SignIn() {
  const [userId, setUserId] = useState()
  const [userPassword, setUserPassword] = useState()
  const [loginStatusMessage, setLoginStatusMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [togglePasswordVisibility, setTogglePasswordVisibility] = useState(false) 
  
  async function  handleSignIn (e){
    setLoading(true)
    setLoginStatusMessage('')
    e.preventDefault()
    const userAuthData ={
      userId : userId,
      userPassword: userPassword,
    }

    await axios.post('https://new-softesc-backend.onrender.com/auth', userAuthData)
    .then((response)=>{
      localStorage.setItem('loginStatus', response.data.loginStatus);
      localStorage.setItem('userRole', response.data.userRole);
      localStorage.setItem('CurrentUserData', JSON.stringify(response.data.accountDetails))
      window.location.reload()
      setLoading(false)
      // ResetInputs()
   
    })
    .catch((error)=>{
      console.log(error )
      if (error.response && error.response.data) {
        setLoginStatusMessage(error.response.data.error);
      } else {
        setLoginStatusMessage("Something Went Wrong");
      }
      setLoading(false)
    })
  }
function ResetInputs (){
  setUserId('')
  setUserPassword('')
}
 
  
  return (
    <div className='sign-in-pallet'>
        <img src={logo} alt="logo" id='school-logo' />
        <h3 id='sch-name'>SOFTESC PORTAL</h3>
        <h3 id='login-h2'>LOGIN</h3>
            <form action="" onSubmit={handleSignIn} className='sign-in-form' >
                {}
            <input 
              type="text" 
              name="" 
              id="userId" 
              required
              value={userId}
              onChange={(e)=>{setUserId(e.target.value)}}
              placeholder='Enter ID' 
              />
           

              <div className="passwordInput">
              <div className=''>
                <input 
                    type= {togglePasswordVisibility?'text':'password'} 
                    name="" 
                    id="userPassword" 
                    required
                    value={userPassword}
                    onChange={(e)=>{setUserPassword(e.target.value)}}
                    placeholder='Enter Password' 
                    />
                </div>
                {togglePasswordVisibility?
                   <RiEyeOffFill id='togglePasswordVisibility' onClick={()=>{setTogglePasswordVisibility(false)}}/>: 
                   <RiEyeFill id='togglePasswordVisibility' onClick={()=>{setTogglePasswordVisibility(true)}}/>
                }
                  </div>
              <button id='form-button' type='submit'>  {loading?<img src={loadergif} alt="Loading ..." style={{width : '3rem'}}/>:'LOGIN'}  </button>
              {loginStatusMessage? <b style={{color: '#ec481a'}}>{loginStatusMessage}</b>: null }   
            </form>
    </div>
  )
}


