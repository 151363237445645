import { createContext, useEffect, useState } from "react";
const UserInterfaceContext = createContext();

const UserInterfaceProvider = ({children})=>{
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [pageName, setPageName] = useState('')
  const [progressPercentage, setProgressPercentage]= useState('0%')
  const [alertMessage, setAlertMessage] = useState()
  const [alertStatus, setAlertStatus] = useState()
  const [displayAlert, setDisplayAlert] = useState(false)
  const [showDeletePallet, setShowDeletePallet] = useState(false)
  // const [login,setLogin] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [smallScreenView, setSmallScreenView] = useState(false)
  const [showFinancialDeletePallet, setShowFinancialDeletePallet] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showReceipt, setShowReceipt] = useState(false)
const [transaction_id,setTransanction_id] = useState('')
const [typeofEntry, setTypeofEntry] = useState('')

  function AlertNotice(message, status){
    setDisplayAlert(true)
    setAlertMessage(message)
    setAlertStatus(status)

    setTimeout(() => {
      setDisplayAlert(false)
    }, 7000);
  
   
  }

  function ToggleShowDelete (){
    setShowDeletePallet(!showDeletePallet)
  }
 
  function ToggleShowFinancialDelete (){
    setShowFinancialDeletePallet(!showFinancialDeletePallet)
  }
 
  function Progress (progressPercentage){
    setProgressPercentage(progressPercentage)
    if (progressPercentage === '100%'){
        setTimeout(() => {
          setProgressPercentage('0%')
        }, 2000);
    }
   
  }

  const toggleSideMenu=()=>{  
    if(showSideMenu){
        setShowSideMenu(false)
    }
    else{
      setShowSideMenu(true)
    }
  }
useEffect(()=>{
  toggleView()
},[])

  function toggleView(){
    if(window.innerWidth< 780){
      setSmallScreenView(true)
      setShowSideMenu(true)
    }
    else{
      setSmallScreenView(false)
    }
    // //console.log(smallScreenView)
  }
  
  window.addEventListener('resize', toggleView)


  return (
    <UserInterfaceContext.Provider value={
        {
        // this is for the side menu state     
        showSideMenu, 
        setShowSideMenu, 
        toggleSideMenu,

        smallScreenView,

        // this is for the progress bar states 
        progressPercentage,
        Progress,


        // this is the the page name at the main header
        pageName,
        setPageName,
        

        //this is for the alert notice states
        AlertNotice,
        alertMessage,
        alertStatus,
        displayAlert,
        setDisplayAlert,
        ToggleShowDelete,
        showDeletePallet,
        setShowDeletePallet,



        showFinancialDeletePallet,
        setShowFinancialDeletePallet,
        ToggleShowFinancialDelete,

        // this is to toggle button between active and disabled state when sending requests 
          isSubmitting,
          setIsSubmitting, 




          //for the passing of transaction id for the delete prompt
          typeofEntry,
          setTypeofEntry,
          transaction_id,
          setTransanction_id,


          //this is for the loading page
          loading,
          setLoading,



          //this is for toggline receipt pallet
          showReceipt,
          setShowReceipt,
        }}>
      {children}
    </UserInterfaceContext.Provider>
  )
}

export {UserInterfaceContext, UserInterfaceProvider}