import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import SPHeader from './SPCOMPONENTS/sp-header'
import SPSideMenu from './SPCOMPONENTS/sp-sidemenu'
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext';
import { Navigate } from 'react-router-dom';
function SPFinance() {
  const {Progress} = useContext(UserInterfaceContext)

  const {CurrentUserData } = useContext(UserAuthenticationContext)
  const [userId, setUserId] = useState(CurrentUserData?.refNumber)
  const [userData, setUserData] = useState([])
  const [userFinancialData, setUserFinancialData] = useState([])

  
  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('CLASS 1')
    }
    else if(stage===2){
        return('CLASS 2')
    }
    else if(stage===3){
        return('CLASS 3')
    }
    else if(stage===4){
        return('CLASS 4')
    }
    else if(stage===5){
        return('CLASS 5')
    }
    else if(stage===6){
        return('CLASS 6')
    }
    else if(stage===7){
        return('CLASS 7 / JHS 1')
    }
    else if(stage===8){
        return('CLASS 8 / JHS 2')
    }
    else if(stage===9){
        return('CLASS 9 / JHS 3')
    }
  }

    useEffect(()=>{
      //console.log('student id in details : ', userId)
      Progress('30%')
          // axios.get('https://new-softesc-backend.onrender.com/students'
      axios.get('https://new-softesc-backend.onrender.com/students'
      ,{
        params:{
          refNumber : userId,
        }
      })
    .then((response)=>{
      Progress('100%')
      setUserData(response.data)
      ////console.log(response.data)
      //console.log('userdata',userData)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log(error)
    })
    },[userId])


    useEffect(()=>{
      //console.log('student id in details : ', userId)
      Progress('30%')
          // axios.get('https://new-softesc-backend.onrender.com/students'
      axios.get('https://new-softesc-backend.onrender.com/finance/studentTerm'
      ,{
        params:{
          refNumber : userId,
        }
      })
    .then((response)=>{
      Progress('100%')
      setUserFinancialData(response.data)
      console.log(response.data)
      //console.log('userdata',userData)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log(error)
    })
    },[userId])


    if(userId===''){
      return <Navigate to='/StudentDashboard'/>
    }
    
  return (
    <div className='App'>
      <SPSideMenu/>
      <div className='main-menu'>
        <SPHeader/>
        <div className='fee-collection content-loader'>
        {/* <FinancialActions/> */}
        <div className="financial-info">
              <h3>Financial Information</h3>

              {userFinancialData.length>0?userFinancialData.map((financeData, index)=>(
                <div key={index} style={{position : 'relative',marginBottom: '1rem' }}>
                <h3 htmlFor="" style={{color: 'white', position:'sticky', top: '2.2rem', zIndex : '2'}}>Year : {financeData.academicYear} | Term : {financeData.academicTerm}  </h3>
                {/* <label htmlFor="">Academic Term :{financeData.academicTerm}  </label> <br /> */}
                <b>Stage :.................. {GenerateStageName(financeData.stageValue)}  </b><br />
                <b>Total Fees :........... {financeData.financialData.totalFees?financeData.financialData.totalFees:0} </b><br />
                <b>Total Paid :........... {financeData.financialData.paidFees?financeData.financialData.paidFees:0}</b><br />
                <b>Total Owing :....... {financeData.financialData.totalFees - financeData.financialData.paidFees} </b><br />
                <b> Financial Status :. {(financeData.financialData.totalFees - financeData.financialData.paidFees)>0? <b style={{color: 'red'}}>OWING</b> : <b style={{color:'green'}}>PAID</b> } </b>
                <br /> <br /><hr/>
            </div>
              ))    
              :null}
              </div> 
          </div>

      </div>
    </div>
  )
}

export default SPFinance