import React from 'react'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'


function UpdateClass() {
  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <div className='update-class'>
        <h3>Update Class</h3>
        <div className="update-class-body">
            <form action="">
                <label htmlFor="">Class ID:</label>
                <input type="text" />
                <label htmlFor="">Name of Class Teacher: </label>
                <input type="text" />
                
            </form>
        </div>
    </div>
        </div>
      </div>
    
  )
}

export default UpdateClass