import axios from 'axios'
import { useEffect, useState, useContext } from 'react'
import TPHeader from './TPCOMPONENTS/tp-header'
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu'
// import BPHeader from './TPCOMPONENTS/bp-header'
// import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import logo from '../Pages/student/School Crest.png'
import { StudentActions } from './TPCOMPONENTS/tp-reusables'

import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { UserInterfaceContext } from '../Contexts/ui-context'
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext'
import { Alert } from './TPCOMPONENTS/tp-reusables'


function TPGenerateReport() {

  const {Progress,AlertNotice} = useContext(UserInterfaceContext)
    const componentRef = useRef(null); 
    const {CurrentUserData} = useContext(UserAuthenticationContext)
    const [academicYear, setAcademicYear] = useState([])
    const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
    const [academicTerm, setAcademicTerm] =useState([])
    const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
    const [allStudents, setAllStudents] = useState([])
    const [selectedStudentDetails, setSelectedStudentDetails] = useState([])
    const [selectedStudent, setSelectedStudent] = useState('')
    const [selectedStage, setSelectedStage ] = useState(CurrentUserData?.stage)
    const [studentGrades, setStudentGrades] = useState([])
    const [showResults, setShowResults] = useState(false)
    const [termSummary, setTermSummary]= useState({})
   

    

    function ResetInputs (){
        setSelectedAcademicTerm('')
        setSelectedAcademicYear('')
        // setSelectedStage('')
        setSelectedStudent('')
    }


    const fetchAcademicYear = async () => {
        try {
          Progress('30%');
          const academicYearResponse = await axios.get('https://new-softesc-backend.onrender.com/academicYear', {
           
          });
        //   //console.log(academicYearResponse);
          setAcademicYear(academicYearResponse.data);
          Progress('100%');
        } catch (error) {
          console.error(error);
          Progress('0%');
        }
      };
  


      const fetchAcademicTerm = async () => {
        try {
          //console.log('selected academic year', selectedAcademicYear)
          if(selectedAcademicYear ==='') {
            return
          }
          else{
            Progress('70%');
          const academicTermResponse = await axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
            params:{
                academicYearName : selectedAcademicYear,
            }
          });
        //   //console.log(academicTermResponse);
          setAcademicTerm(academicTermResponse.data);
          Progress('100%');
            // return
          }
        } catch (error) {
          console.error(error);
          Progress('0%');
        }
      };



      const fetchAllStudents = async () => {
        try {
        //   //console.log('selected class', selectedAcademicYear)
          if(selectedStage !=='') {
            Progress('70%');
            console.log(selectedStage)
          const allStudentsResponse = await axios.get('https://new-softesc-backend.onrender.com/students',{
            params:{
                stage : selectedStage,
            }
          });
        //   //console.log(allStudentsResponse);
          AlertNotice(allStudentsResponse.data.message)
          setAllStudents(allStudentsResponse.data);
          Progress('100%');
          }
          else{
            return
          }
        } catch (error) {
            AlertNotice(error.response.data.error)
          console.error(error);
          Progress('0%');
        }
      };
    
      
    function FetchAcademicReport (e){
        e.preventDefault()
        //getting students details on report
        if(selectedAcademicTerm&&selectedAcademicYear&&selectedStudent&&selectedStage!==''){
            Progress('20%')
        axios.get('https://new-softesc-backend.onrender.com/students',{
            params :{
                userId : selectedStudent,
            }
        } 
        )
        .then((response)=> {
            Progress('100%')
            setSelectedStudentDetails(response.data)
            ////console.log(response.data)
            ResetInputs()
        })
        .catch((error)=>{
            Progress('0%')
            //console.log(error)
            
        })
        //getting students grades 
        axios.get('https://new-softesc-backend.onrender.com/grades',{
            params :{
                stage: selectedStage,
                student : selectedStudent,
                academicYear : selectedAcademicYear,
                academicTerm : selectedAcademicTerm,
            }
        }
        )
        .then((response)=> {
            Progress('100%')
            setStudentGrades(response.data.studentGrades)
            setTermSummary(response.data.TermSummary)
            //  {AttendanceCount, PresentDays, TermBeginDate, TermEndDate} : response.data.termSummary
            ////console.log(response.data)
        })
        .catch((error)=>{
            Progress('0%')
            //console.log(error)
        })
        setShowResults(true)
        }
        else{
            return
        }
    }



    useEffect(() => {
        fetchAcademicYear();
    }, []);

    useEffect(() => {
        if (selectedAcademicYear) {
            fetchAcademicTerm();
        }
    }, [selectedAcademicYear]);

    useEffect(() => {
        if (selectedStage) {
            fetchAllStudents();
        }
        if(selectedStage===0){
            fetchAllStudents()
        }
    }, [selectedStage]);


    function checkGrade (totalscore) {
        if(totalscore>=90 && totalscore <=100){
            return(1)
        }
        else if(totalscore>=80 &&totalscore<=89){
            return(2)
        }
        else if(totalscore>=70 && totalscore <=79 ){
            return(3)
        }
        else if(totalscore>=60 && totalscore <=69){
            return(4)
        }
        else if(totalscore>=50 && totalscore<=59){
            return(5)
        }
        else if(totalscore>=44 && totalscore<=49){
            return(6)
        }
        else if(totalscore>=40 && totalscore<=44){
            return(7)
        }
        else if(totalscore>=30 && totalscore<=39){
            return(8)
        }
        else if(totalscore>=1 && totalscore<=29){
            return(9)
        }
        else {
            return('---')
        }  
    }

    function CheckRemarks (totalscore) {
        if(totalscore>=90 && totalscore <=100){
            return('HIGHEST')
        }
        else if(totalscore>=80 &&totalscore<=89){
            return('HIGHER')
        }
        else if(totalscore>=70 && totalscore <=79 ){
            return('HIGH')
        }
        else if(totalscore>=60 && totalscore <=69){
            return('HIGH AVERAGE')
        }
        else if(totalscore>=50 && totalscore<=59){
            return('AVERAGE')
        }
        else if(totalscore>=44 && totalscore<=49){
            return('LOW AVERAGE')
        }
        else if(totalscore>=40 && totalscore<=44){
            return('LOW')
        }
        else if(totalscore>=30 && totalscore<=39){
            return('LOWER')
        }
        else if(totalscore>=1 && totalscore<=29){
            return('LOWEST')
        }
        else {
            return('---')
        }  
    }

    function CummulativeExamScore() {
        let ExamsTotal = 0;
        studentGrades.forEach((grades, index) => {
            ExamsTotal += parseFloat(grades.examScore);
        });
        return ExamsTotal?ExamsTotal:0;
    }

    function CummulativeClassScore() {
    let ClassTotal = 0;
    studentGrades.forEach((grades, index) => {
        ClassTotal += parseFloat(grades.classScore);
    });
    return ClassTotal?ClassTotal:0;
    }

    function CummulativeGrandScore() {
        let GrandTotal = 0;
        studentGrades.forEach((grades, index) => {
            GrandTotal += parseFloat(grades.classScore) + parseFloat(grades.examScore);
        });
        return GrandTotal?GrandTotal:0;
    }
    function HeadMasterComment(score) {
        if (score >= 950) {
            return "You're already excelling beyond expectations. Keep shining!";
        } else if (score >= 900) {
            return "You're doing exceptionally well! Keep up the amazing effort!";
        } else if (score >= 850) {
            return "You're making fantastic progress! Keep pushing forward!";
        } else if (score >= 800) {
            return "You're doing great! Keep up the excellent work!";
        } else if (score >= 750) {
            return "You're on the right track! Keep up the good work!";
        } else if (score >= 700) {
            return "Keep going strong! You're doing well!";
        } else if (score >= 650) {
            return "You're making steady progress! Keep it up!";
        } else if (score >= 600) {
            return "You're doing good! Keep working hard!";
        } else if (score >= 550) {
            return "You're making progress! Keep practicing!";
        } else if (score >= 500) {
            return "You're on the right path! Keep pushing!";
        } else if (score >= 450) {
            return "You're getting there! Keep moving forward!";
        } else if (score >= 400) {
            return "You're making efforts! Keep focusing!";
        } else if (score >= 350) {
            return "Stay positive! You can do it!";
        } else if (score >= 300) {
            return "Keep going! Your efforts will pay off!";
        } else if (score >= 250) {
            return "You're making strides! Keep pushing through!";
        } else if (score >= 200) {
            return "Stay motivated! You can turn it around!";
        } else if (score >= 150) {
            return "Don't lose hope! Keep pushing forward!";
        } else if (score >= 100) {
            return "You're stronger than you think! Keep pushing forward!";
        } else if (score >= 50) {
            return "Every step forward counts! Keep moving forward!";
        } else {
            return "Every setback is a setup for a comeback! Keep pushing forward!";
        }
    }

    function ClassTeachersComment(score) {
        if (score >= 950) {
            return "Excellent! Keep shining!";
        } else if (score >= 900) {
            return "Exceptional! Keep it up!";
        } else if (score >= 850) {
            return "Fantastic progress!";
        } else if (score >= 800) {
            return "Great job!";
        } else if (score >= 750) {
            return "On track!";
        } else if (score >= 700) {
            return "Keep going strong!";
        } else if (score >= 650) {
            return "Steady progress!";
        } else if (score >= 600) {
            return "Doing good!";
        } else if (score >= 550) {
            return "Making progress!";
        } else if (score >= 500) {
            return "On the path!";
        } else if (score >= 450) {
            return "Getting there!";
        } else if (score >= 400) {
            return "Keep focusing!";
        } else if (score >= 350) {
            return "Stay positive!";
        } else if (score >= 300) {
            return "Keep going!";
        } else if (score >= 250) {
            return "Making strides!";
        } else if (score >= 200) {
            return "Stay motivated!";
        } else if (score >= 150) {
            return "Don't lose hope!";
        } else if (score >= 100) {
            return "You're stronger!";
        } else if (score >= 50) {
            return "Every step counts!";
        } else {
            return "Setback? Comeback!";
        }
    }

    function generateSubjectName (subjectCode){
        if(subjectCode.slice(0,3).toUpperCase()==='SCI'){
            return('SCIENCE')
        }
        else if(subjectCode.slice(0,3).toUpperCase()==='NUM'){
            return('MATHEMATICS')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='LAN'){
            return('LANGUAGE AND COMMUNICATION')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='LIT'){
            return('LITERACY')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='CRE'){
            return('CREATIVE ARTS')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='HIS'){
            return('HISTORY')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='SOC'){
            return('SOCIAL STUDIES')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='COM'){
            return('COMPUTING')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='OWO'){
            return('OWOP')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='CAR'){
            return('CAREER TECH')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='GH.'){
            return('GHANAIAN LANGUAGE - TWI')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='FRE'){
            return('FRENCH')

        }
        else if(subjectCode.slice(0,3).toUpperCase()==='RME'){
            return('RME')

        }
     
    }

    function findBestSubjects(gradesArray) {
        const subjectsTotal = gradesArray.map(grade => ({
            subject: generateSubjectName(grade.subject), // Transform subject code to subject name
            totalScore: grade.classScore + grade.examScore
        }));
    
        subjectsTotal.sort((a, b) => b.totalScore - a.totalScore);
    
        // Extract the subject names and concatenate them with a space
        const bestSubjects = subjectsTotal.slice(0, 2).map(subject => subject.subject).join(' and ');
    
        return bestSubjects;
    }
    

    function getWorkingDays(startDate, endDate) {
        // Parse the dates
        let start = new Date(startDate);
        let end = new Date(endDate);
    
        // Calculate the total number of days
        let totalDays = (end - start) / (1000 * 60 * 60 * 24) + 1;
    
        // Initialize the number of weekend days
        let weekendDays = 0;
    
        // Loop through each day in the interval to count the weekends
        for (let i = 0; i < totalDays; i++) {
            let currentDate = new Date(start);
            currentDate.setDate(start.getDate() + i);
            let day = currentDate.getDay();
            if (day === 6 || day === 0) { // 6 = Saturday, 0 = Sunday
                weekendDays += 1;
            }
        }
    
        // Calculate the number of working days
        let workingDays = totalDays - weekendDays;
        return workingDays;
    }

    function printPDF() {
        const input = document.getElementById('academic-report'); // Replace 'academic-report' with the id of your report container
        html2canvas(input,{
            scale: 1,
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = canvas.height * imgWidth / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(selectedStudentDetails[0].firstName + ' ' + selectedStudentDetails[0].lastName + ' ' + termSummary.academicYear +' '+ termSummary.academicTerm + ' '+ 'TERM_REPORT.pdf');
            });
    }




  return (
    <div className='App'>
      <TPSideMenu/>
      <Alert/>
      <div className='main-menu'>
        <TPHeader/>
        <div  className='report content-loader'>
            {/* the main menu content goes here  */}
            <StudentActions/>
            <form action="" className='sorting sticky-left'>
                <div className="sorting-body">
                  
                    
                    <div className="input ">
                        <label htmlFor="">Student</label>
                        <select name="" id=""
                            value={selectedStudent} 
                            required
                            onChange={(e)=>{setSelectedStudent(e.target.value)}}
                        >
                            <option value="" disabled hidden>Choose a Student</option>
                            {allStudents.map((student, index)=>(
                                <option  key={index} value={student.refNumber}>{student.firstName +' '+ student.middleName +' ' +  student.lastName}</option>

                            ))}                        
                        </select>
                    </div>

                    <div className="input ">
                        <label htmlFor="">Year</label>
                        
                        <select 
                                name="" 
                                id=""
                                required
                                value={selectedAcademicYear}
                                onChange={(e) => { setSelectedAcademicYear(e.target.value) }}
                            >
                                <option value="" disabled hidden>Select academic year</option>
                            
                                {academicYear.length>0? academicYear.map((year, index) => (
                                    <option key={index} value={year.academicYearName}>
                                        {year.academicYearName}
                                    </option>
                                )):null}
                                
                            </select>
                    </div>
                    
                    <div className="input ">
                        <label htmlFor="">Term</label>
                        <select 
                            name="" 
                            id="" 
                            required
                            value={selectedAcademicTerm}
                            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
                            >
                            <option value="" disabled hidden>select an academic term</option>
                            {academicTerm.map((term, index)=>(
                                <option key={index} value={term.academicTermName}>{term.academicTermName}</option>

                            ))}

                        </select>               
                    </div>
                </div>
                <button type='submit' onClick={FetchAcademicReport} >Generate Report</button>
           </form>


          {/* {showResults? <ReactToPrint
                        trigger={() => <button>Print to PDF</button>}
                        content={() => componentRef.current} // Ref to your academic report component
                    />:null} */}


       {/* //academic report  */}      
        {showResults?<div className='academic-report' id='academic-report'>
            {/* <br /><br /> */}
        <div className="school-header">
            <div className="school-logo">
                <img src={logo} alt="School Logo" />
            </div>
            <div className="school-details">
                <h3>STAR OF THE EAST SCHOOL COMPLEX</h3>
                <h4>Excellence Through Christ</h4>
                <p>Email: softesc.online@gmail.com</p>
                <p>Tel: +233 000 000 000 / +233 000 000 000</p>
            </div>
            <div className="school-logo">
                <img src={logo} alt="School Logo" />

            </div>
        </div>
        {
            selectedStudentDetails?.map((details, index)=>(
                <div className="report-details">
                    <label htmlFor="">STUDENT ID : {details.refNumber}</label> <br />
                    <label htmlFor="">Name : {details.firstName + ' ' + details.middleName + ' ' + details.lastName}</label> <br />
                    <label htmlFor="">Class : {details.stage}</label> <br />
                    <label htmlFor="">Class Teacher : {termSummary.classTeacher} </label><br />
                    <label htmlFor="">Academic Year : {termSummary.academicYear}</label>
                    <label htmlFor="">Academic Term : {termSummary.academicTerm}</label>
                    {/* <label htmlFor="">Promoted to : null</label> */}
                </div>
            ))
        }
        <div className="academic-results">
            <table>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th style={{textAlign: 'left'}}>Subject</th>
                        <th>Class Score (50%)</th>
                        <th>Exam Score (50%)</th>
                        <th>Total (100%)</th>
                        <th>GRADE</th>
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    {studentGrades.length>0?studentGrades.map((grades, index)=>(
                        
                    // <>
                        <tr key={index}>
                        <td>{index+1}</td>
                        <td style={{textAlign: 'left'}}>{generateSubjectName(grades.subject)}</td>
                        <td>{grades.examScore}</td>
                        <td>{grades.classScore}</td>
                        <td>{grades.examScore+grades.classScore}</td>
                        <td>{checkGrade(grades.examScore + grades.classScore)}</td>
                        <td id='no-wrap'>{CheckRemarks(grades.examScore + grades.classScore)}</td>
                    </tr>
                    
                    )):<tr > <td colSpan={7} style={{color: 'red'}}> <b>NO ACADEMIC DATA TO SHOW</b> </td> </tr>
                    
                    // </>
                    }
                    <tr style={{backgroundColor: '#626e73', color: 'white'}}>
                        <td style={{textAlign: 'left'}}>| | | | | |</td>
                        <td><b>TOTAL</b></td>
                        <td>{CummulativeExamScore()}</td>
                        <td>{CummulativeClassScore()}</td>
                        <td>{CummulativeGrandScore()+'/'+(studentGrades.length)*100}</td>
                        <td>---</td>
                        <td>---</td>
                    </tr>
                    
                   
                </tbody>
            </table>
            <br />
        </div>
        <div className="term-summary">
            <label htmlFor="">Current Term Begin : <em> {new Date(termSummary.TermBeginDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</em></label>
            <label htmlFor="">Current Term Ends : <em>{new Date(termSummary.TermEndDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</em></label>
            <label htmlFor="">Next Term Begins : <em>{new Date(termSummary.NextTermReopenDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</em></label>
            <label htmlFor="">
                {/* Total Attendance :  <em> <b>{termSummary.PresentDays}</b> out of <b>{termSummary.AttendanceCount}</b></em> <br /> */}
                Total Attendance :  <em> <b>{termSummary.PresentDays}</b> out of <b>{Math.round(getWorkingDays(termSummary.TermBeginDate, termSummary.TermEndDate))-(termSummary.numOfBreakDays?termSummary.numOfBreakDays:0)}</b></em>
            </label> <br /> <br />
            <label htmlFor="">Interest : <em>{findBestSubjects(studentGrades)}</em></label>
        </div>
       <hr />
        <div className="classteacher-section">
            <label htmlFor="">Class Teacher's Comment : <em>{ClassTeachersComment(CummulativeGrandScore())}</em> </label> <br />
            <label htmlFor="">Class Teacher's Signature :  </label>
        </div>
        <hr />
        <div className="headmaster-section">
            <label htmlFor="">Head Master's Comment : <em>{HeadMasterComment(CummulativeGrandScore())}</em></label><br />
            <label htmlFor="">Head Master's Signature : </label>
        </div>
        <hr />
        <div className="post-detail">
            <p>P.O.Box 365 TA</p>
            <p>Taifa-Accra </p>
        </div>
        
        </div>: null }
        
        {/* Preschool */}
        {/* <br /> */}
        {
            showResults?<div id="submit-reset-buttons">
            <button onClick={printPDF}>Print to PDF</button>
             <button style={{backgroundColor: 'red'}} onClick={()=>{setShowResults(false); ResetInputs()}}>Clear</button>
             </div>:null
        }
                </div>

      </div>
    </div>
  )
}

export default TPGenerateReport