import React, { useContext, useEffect } from 'react'
import { NavLink, Navigate } from 'react-router-dom'
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext';


function Logout() {

  return (
    <div className='Logout'>
      <div className="logout-pallet">
        <p>Logout?</p>
        <div className="logout-buttons">
        <button id='logout-button' onClick={
          ()=>{localStorage.clear();     
              window.location.reload();
              return <Navigate to = '/'/>}}
        >Yes </button>
        <NavLink id='Link' to='/MainDashBoard'><button id='logout-button' >Cancel</button></NavLink>
        </div>
      </div>
    </div>
  );
}

export default Logout