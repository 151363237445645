import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import TPHeader from './TPCOMPONENTS/tp-header'
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu'
import { StudentActions } from './TPCOMPONENTS/tp-reusables'
import { NavLink } from 'react-router-dom'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { UserDataContext } from '../Contexts/user-data'
import { FaEye, FaUser } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext'


function TPStudents() {

  const {Progress,   } = useContext(UserInterfaceContext)
  const {userId,setUserId} = useContext(UserDataContext)
  const {CurrentUserData} = useContext(UserAuthenticationContext)
  const [studentData, setStudentData] = useState([])
  const [name, setName] = useState('')
  const [classValue] = useState(CurrentUserData?.stage)
  const [gender, setGender] = useState('')
  const [house, setHouse] = useState('')
  const [sortingView, setSortingView] = useState(true)
  const [stdStatus, ] = useState('ACTIVE')
  // const [userStatus, setUserStatus] = useState('ACTIVE')
  function toggleSortingView (){
    setSortingView(!sortingView)
  }


  useEffect(()=>{
    Progress('30%')
    // axios.get('https://new-softesc-backend.onrender.com/students')
    axios.get('https://new-softesc-backend.onrender.com/students'
    ,{
      params:{
        name : name,
        stage : classValue,
        gender : gender,
        house: house,
        status : stdStatus
      }
    }
    )
      .then((response) => {
        Progress('100%')
        setStudentData(response.data)
        ////console.log(response.data)
    })
      .catch((error) => {
        Progress('0%')
        console.error(error)});

  },[name,classValue, gender,house, stdStatus])
  return (
    <div className='App'>
      <TPSideMenu/>
      <div className='main-menu'>
        <TPHeader/>
        <div  className='content-loader'>
        <div className='user-features'>
          <StudentActions/>
        
        <div className="sorting">
        <div className="sorting-header" onClick={toggleSortingView}>
           <h2>Sort By:</h2>
          <IoIosArrowDown id='sorting-down-arrow'/>
           </div>          
           <div className='sorting-body' style={{display: sortingView?'none':'flex'}}>
          
          
          <div className="input">
            <label htmlFor="">Name : </label>
            <input 
            type="text" 
            value={name}
            onChange={(e)=> {setName(e.target.value)}}
            placeholder='Search by Name'
            />
          </div>
          
          {/* <div className="input">
            <label htmlFor="">Class: </label>
            <select 
              value={classValue}
              onChange={(e)=>{setClassValue(e.target.value)}}
            >
                <option value="">All </option>
                <option value={-4}>Preschool - Creche</option>
                <option value={-3}>Preschool - Nursery 1</option>
                <option value={-2}>Preschool - Nursery 2</option>
                <option value={-1}>Preschool - Kingdergarten 1</option>
                <option value={0}>Preschool - Kingdergarten 2</option>
                <option value={1}>Class 1</option>
                <option value={2}>Class 2</option>
                <option value={3}>Class 3</option>
                <option value={4}>Class 4</option>
                <option value={5}>Class 5</option>
                <option value={6}>Class 6</option>
                <option value={7}>Class 7 / JHS 1</option>
                <option value={8}>Class 8 / JHS 2</option>
                <option value={9}>Class 9 / JHS 3</option>
            </select>


          </div> */}


          <div className="input">
            <label htmlFor="">Gender: </label>
            <select 
              value={gender}
              onChange={(e)=>{setGender(e.target.value)}}
              >
                <option value="">All </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
            </select>
          </div>

          <div className="input">
            <label htmlFor="">House: </label>
            <select 
              value={house}
              onChange={(e)=>{setHouse(e.target.value)}}
              >
                <option value="">All </option>
                <option value="A">House A</option>
                <option value="B">House B</option>
                <option value="C">House C</option>
                <option value="D">House D</option>
            </select>
          </div>

          {/* <div className="input">
            <label htmlFor="">Student Status: </label>
            <select 
              value={stdStatus}
              onChange={(e)=>{setStdStatus(e.target.value)}}
              >
                <option value="" hidden disabled>Select a status </option>
                <option value="ACTIVE">ACTIVE</option>
                <option value="OUT">SACKED / LEFT</option>
                <option value="GRADUATED">GRADUATED</option>
            </select>
          </div> */}



          </div>
        </div>
        {/* {studentData.length>0?null:<h2 id='no-data-error'>NO STUDENTS DATA TO SHOW</h2> } */}
      </div>
      <div className="container">
          <table className='user-table'>
              <thead>
                <tr>
                  <th>Student ID: </th>
                  <th>Photo</th>
                  <th id='user-name-head'>Name [FMLO]</th>
                  <th>Class </th>
                  <th>Gender</th>
                  <th>House</th>
                  {/* <th>Class Teacher</th> */}
                  {/* <th>
                    School Fees
                    <div id='inner-th'>
                      <em id='paid'>Paid (GHS)</em>  
                      <em id='balance'>Balance(GHS)</em>  
                    </div></th> */}
                  {/* <th>Address</th> */}
                  {/* <th>Date of Birth</th> */}
                  {/* <th>Parent / Guardian</th> */}
                  {/* <th>Telephone</th> */}
                  {/* <th>Email</th> */}
                  <th>Status</th>
                  <th id='user-actions-head'>Action</th>
                  {/* Add more header cells as needed */}
                </tr>
              </thead>
              <tbody>
                {studentData.length>0?(studentData.map((studentData, index)=>(
                    <tr key={index}>
                    <td>{studentData.refNumber}</td>
                    <td style={{textAlign: 'center'}}><FaUser id='user-icon'/></td>
                    <td id='user-name'>{studentData.firstName + " " + studentData.middleName + " " + studentData.lastName }</td>
                    <td>{studentData.stage}</td>
                    <td>{studentData.gender}</td>
                    <td>{studentData.house}</td>
                    {/* <td>{studentData.classTeacher}</td> */}
                    {/* <td id='inner-td'><em id='paid'>{studentData.paidFeesAmount}</em> <em id='balance'>{studentData.feesBalance}</em></td> */}
                    {/* <td id='no-wrap'>{studentData.presentAddress}</td> */}
                    {/* <td>{studentData.dob}</td> */}
                    {/* <td id='no-wrap'>F:{studentData.fatherName }<br /> M:{studentData.motherName}</td> */}
                    {/* <td>F:{studentData.fatherPhone }<br /> M:{studentData.motherPhone}</td> */}
                    {/* <td id='no-wrap'>{studentData.email}</td> */}
                    <td >{studentData.userStatus}</td>
                    {/* <td id='table-actions'><NavLink to='/StudentDetails'><FaEye id='action-see' onClick={()=>{setUserId(studentData.refNumber)}}/></NavLink> <FaEdit/> <FaTrash id='action-delete'/></td> */}
                    <td id='table-actions'>
                      <NavLink to="/TPStudentDetails" onClick={async() => await setUserId(studentData.refNumber)}>
                          <FaEye id="action-see" />
                        </NavLink> 
                      {/* <NavLink to='/StudentUpdate' onClick={async()=> await setUserId(studentData.refNumber)}>
                          <FaEdit id='action-update'/> 
                      </NavLink> */}
                      {/* <NavLink>
                        <FaTrash id='action-delete' onClick={()=>{
                          setShowDeletePallet(true); 
                          setUserId(studentData.refNumber)
                          }}/>
                      </NavLink> */}
                    </td>
                    
                  </tr>
                  ))):
                  (<tr>
                    <td colSpan='22' style={{padding:'1rem', color:'red'}}>NO STUDENT DATA TO SHOW</td>
                  </tr>)
                }
        
              </tbody>
        </table>
      </div>
        </div>

      </div>
    </div>
  )
}

export default TPStudents