import React from 'react'

export function ProcessingPage(){
    return(
      <div className="ProcessingPage">
        <h2>SOFTESC - SMS</h2>
        <div class="loadingio-spinner-ripple-u6a07mxtyh">
        <div class="ldio-9e4gqum7g28">
          <div></div>
          <div></div>
        </div>
      </div>
      </div>
  
    )
  }